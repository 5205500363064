import { createDocument } from "redux/actions/vdocsActions";
import store from "redux/store";
import useEditorStore from "store/useEditorStore";
export const isPreviousBlockEmpty = (previousBlockIndex: number) => {
  const { blocks } = store.getState().vdocs.editorInstance;
  const { getBlockByIndex } = blocks;
  const previousBlock = getBlockByIndex(previousBlockIndex);
  return typeof previousBlock !== "undefined" && previousBlock.name !== "image" && previousBlock.isEmpty;
};

export const getCurrentDocumentKey = async () => {
  const currentEditorDocumentTitle = useEditorStore.getState().editorDocumentTitle;
  const currentDocument = store.getState().vdocs.currentDocument;
  if (currentDocument && currentDocument["document_key"] !== "new") {
    return currentDocument["document_key"];
  }
  const createdDocument = await store.dispatch(createDocument({ origin: "vdocs", title: currentEditorDocumentTitle }));
  if (createdDocument.error_message) return;
  return createdDocument["document_key"];
};

export const convertMarkdownBulletToBoldHTML = (text: string) => {
  // Regular expression to match markdown bullet points
  const boldMarkdownRegex = /\*\*(.*?)\*\*/;

  // Check if the string matches the bullet point format
  const match = text.match(boldMarkdownRegex);
  if (match) {
    // If it matches, return the text wrapped in <b> tags
    return `<b>${match[1].trim()}</b>`;
  }

  // If no match, return the original text
  return text;
};

export const convertMarkdownHeadingToBoldHTML = (text: string) => {
  const headingMarkdownRegex = /^(#+)\s+(.*)/;
  const match = text.match(headingMarkdownRegex);
  if (match) {
    return `<b>${match[2].trim()}</b>`;
  }
  return text;
};
