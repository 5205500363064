// auth 2.0
import React from "react";
import styles from "./VideoDocumentEditor.module.scss";
import "./VideoDocumentEditor.css";
import { connect } from "react-redux";
import {
  createDocument,
  updateDocument,
  createFolder,
  moveDocument,
  registerVideo,
  updateCurrentVideo,
  registerClip,
  fetchDocuments,
  fetchDocument,
  setIsCaptureAreaActive,
  setIsGettingClipRecordingMediaPermission,
  setCaptureAreaRect, // web
  setIsListOpen,
  setIsEditorOptionOpen,
  setIsHelpMenuOpen,
  setShowSearchPopup,
  setShowSharePopup,
  setShowDeletedDocumentsPopup,
  setEditorWrapperClassName,
  setIsRecordActive,
  setClipRecordingCountdownNumber,
  setShowDisableMediaRecordingModal,
  setMediaRecordingChangeDirection,
  setIsExtensionMediaPermitted,
  setIsGettingExtensionMediaPermission,
  setShowExtensionMediaPermissionModal,
  // desktop
  setIframePort, // not necessary since v1.3.0
  setDesktopDefaultCaptureRect, // not necessary since v1.3.0
  setDesktopCaptureRect, // not necessary since v1.3.0
  setSelectedSourceData,
  setIsCaptureAreaSetForSnipCapture,
  setCaptureRect,
  setIsCaptureAreaSetForOneClickCapture,
  setIsCaptureAreaReset, // not necessary since v1.3.0
  setSelectableProcesses,

  //tablet
  setIsImageCroppingModalOpen,
  setImageCroppingModalImageSource,
  setImageCropArea,
  setEditImageCropArea,
  setShouldUpdateImageByCrop,
  setCroppingImageBlockData,
  setTabletDefaultCropArea,
} from "redux/actions/vdocsActions";

// for router history
import { withRouter } from "react-router-dom";
//for loading state
import EditorNoteLoadingScreen from "components/editorLoadingStates/EditorNoteLoadingScreen";
import EditorComponent from "components/editor/EditorComponent";
import DocumentSearch from "../../components/popups/DocumentSearch";
import DocumentShare from "../../components/popups/DocumentShare";
import DeletedDocuments from "../../components/popups/DeletedDocuments";
import DocumentListView from "../../components/listItem/DocumentListView";
import ReactResizeDetector from "react-resize-detector";
import { InputGroup, FormControl } from "react-bootstrap";
import { isMacOs } from "react-device-detect";
import Sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { checkIfVersionIsAvailable } from "utils/etc/versionChecker";
import { getApplicationType, findLatestOfTwoVersions, isMobileApp, isTabletApp, getEventLocationMode } from "utils/utils";
import { sendVideoInfoRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { screenCapture } from "utils/capture/screenCapture";
import { recordClip, stopRecordClip } from "utils/record/recordClip";
import { uploadBase64Image } from "utils/aws/s3Interface";
import {
  setCheckIsExtensionMediaPermittedResponseListener,
  setCheckIsSlidInstantLaunchedListener,
  setIsExtensionClickedListener,
  setScreenCaptureResponseListener,
  setSetCurrentCaptureAreaResponseListener,
  setSetVideoArrayBufferResponseListener,
  setSnipCaptureIsDoneResponseListener,
} from "utils/extensionInterface/setListenerFromExtension";
import {
  sendResetCaptureAreaForOneClickCaptureRequestToParentWindow,
  sendCancelCaptureAreaRequestToParentWindow,
  sendSetSnipCaptureAreaRequestToParentWindow,
  sendSetFocusOnIframeRequestToParentWindow,
  sendHideVideoRequestToParentWindow,
  sendVideoPlaceholderPositionToParentWindow,
  sendRemoveSnipCaptureAreaRequestToParentWindow,
  sendGoBackExtensionViewerButton,
  sendCheckIsExtensionMediaPermitted,
} from "utils/extensionInterface/sendToExtension";
import * as Sentry from "@sentry/browser";
import { sendAmplitudeData } from "utils/amplitude";
import { setDefaultSettingCookie } from "utils/cookies/cookies";
import { uploadFile } from "utils/aws/s3Interface";
import { v4 as uuidv4 } from "uuid";
import { withCookies } from "react-cookie";
import env from "config/env";
import urls from "config/disable_clip_recording_urls.json";
import emitter from "react-ab-test/lib/emitter";
import { withHooks } from "withHooks";
import {
  showDownloadExtensionModal,
  showFailToLoadWindowModal,
  showErrorOnDesktopModal,
  showWantToContinueCapturingModal,
  showStopCapturingOnDesktopModal,
  showCustomModal,
  showPhotoLibraryPermissionRequiredModal,
} from "utils/modal";
import { initEventTracking, trackEvent } from "utils/eventTracking";
import { setExtensionVersion } from "redux/actions/slidGlobalActions";
import { fabric } from "fabric";
import { connectPortToExtension } from "utils/extensionInterface/connectPortToExtension";
import { Typography11, Icon, Button, Dim, Typography13, Tooltip } from "@slid/slid-ips";
import HelpMenu from "components/DropdownMenus/HelpMenu";
import { withTranslation } from "react-i18next";
import DesktopCaptureGuideTooltip from "components/Tooltips/DesktopCaptureGuideTooltip";
import { PopupContainer, PopupLayer } from "styles";
import styled, { css } from "styled-components";
import SelectWindowModal from "./SelectWindowModal";
import { OneClickIcon } from "components/icons/OneClickIcon";
import ScaleFactorChangeModal from "./ScaleFactorChangeModal";
import ExtensionMediaPermissionModal from "components/ExtensionPermissionModal/ExtensionMediaPermissionModal";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import DisableMediaRecordingModal from "../../components/popups/DisableMediaRecordingModal";
import { alertExtensionMediaPermissionGranted } from "components/alerts";
import PermissionGrantedAlertMessage from "components/alerts/PermissionGrantedAlertMessage";
import SavedTimeBanner from "components/banner/SavedTimeBanner";
import StudyChallengeSavedTimeBanner from "components/banner/StudyChallengeSavedTimeBanner";
import { SlidFeatures } from "utils/privilegeManager";
import { SliddyIcon } from "components/icons/SliddyIcon";
import SlidGPT from "components/SlidGPT/SlidGPT";
import TabletStartCapturingButtonToolTip from "../../components/Tooltips/TabletStartCapturingButtonToolTip";
import { eventTypes } from "types/eventTracking";
import ImageCroppingModal from "../../components/NoteEditor/ImageCroppingModal";
import TabletImageCroppingModal from "../../components/tablet/TabletImageCroppingModal";
import { UserMembership } from "types/globals";
import SmartLiveTextVideoNoteButton from "components/SmartLiveText/SmartLiveTextVideoNoteButton";
import AutoNotesVideoNoteButton from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesVideoNoteButton";
import { getCurrentDocumentKey, isPreviousBlockEmpty } from "utils/editor/util";
import { withChannelTalk } from "hooks/withHooks/withChannalTalk";
import DesktopSmartLiveTextVideoNoteButton from "../SmartLiveText/DesktopSmartLiveTextVideoNoteButton";
import { setIsSTTToggledOn, setShowSmartLiveTextView, setStopDesktopSTT, setIsGettingSTTMediaPermission } from "../../redux/actions/sttActions";
import { VideoNoteStudyChallengeInfo } from "components/myNotes/StudyChallenge";
import VideoNoteHeader from "./components/VideoNoteHeader";
import AutoNotesMainUI from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesMainUI";
import { setIsGettingAutoNotesMediaPermission } from "redux/modules/autoNotesSlice";

const ReactSweetalert = withReactContent(Sweetalert);
const CLIP_RECORDING_MAX_SECONDS = 60;
const WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION = "1.6.0";
const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

let shouldRedrawArea;
let mouseDownEventsToIgnore;
let rect;
let initX, initY;
let mouseX, mouseY;
let canvas;
class VideoDocumentEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      onboardingStep: 1,
      isCapturing: false,
      isSnipCapturing: false,
      isCaptureOptionActive: true, // are set capture area & auto capture are folded ? (happens when editor width is too small)
      isDesktopCaptureAreaSet: false, // style the capture btn different;y
      openRecentNote:
        this.props.cookies.get("SlidCustomSetting") && this.props.cookies.get("SlidCustomSetting").openRecentNote ? this.props.cookies.get("SlidCustomSetting").openRecentNote === "true" : false,
      isWebCaptureAvailable: false,
      isFolderSelectorActive: false,
      currentCumFolderName: null,
      isSlidInstantLaunched: false,
      showDesktopCaptureTooltip: true,
      isDesktopCaptureAreaSetting: false,
      shouldShowScaleFactorModal: false,
      isHelpButtonHoveredOnce: false,
      isSTTHovered: false,
      isVideoRecordHovered: false,

      // Tablet
      shouldShowTabletCaptureGuideTooltip: true,
      shouldShowTabletCaptureGuideButton: true,
    };
    this.isGuestModeAlertShown = false;
    this.searchPopupRef = React.createRef();
    this.sharePopupRef = React.createRef();
    this.disableMediaRecordingModalRef = React.createRef();
    this.deletedDocumentsPopupRef = React.createRef();
    this.listContainerRef = React.createRef();
    this.editorComponentRef = React.createRef();
    this.desktopAuthLinkInputRef = React.createRef();
    this.countdownId = null;
    this.loaderBlockKeys = [];
    this.clipRecordingMaxCountdownNumber = CLIP_RECORDING_MAX_SECONDS;
    this.manualCapture = this.manualCapture.bind(this);
    this.setMessageListenerFromVideo = this.setMessageListenerFromVideo.bind(this);
    this.clipRecordStart = this.clipRecordStart.bind(this);
    this.clipRecordStop = this.clipRecordStop.bind(this);
    // desktop
    this.onMessage = this.onMessage.bind(this);
    this.sendMessageToPrimary = this.sendMessageToPrimary.bind(this);
    this.captureResponseHandlerWithoutTimestamp = this.captureResponseHandlerWithoutTimestamp.bind(this);
    this.getImageBlockDataWithoutTimestamp = this.getImageBlockDataWithoutTimestamp.bind(this);

    this.onMobileAppMessage = this.onMobileAppMessage.bind(this);
  }

  componentDidMount() {
    const platform = getApplicationType();
    if (platform === "extension") {
      sendGoBackExtensionViewerButton();
      setScreenCaptureResponseListener({
        responseHandler: this.captureResponseHandler.bind(this),
      });
      setSetCurrentCaptureAreaResponseListener({
        responseHandler: async (receivedData) => {
          this.props.setCaptureAreaRect({
            top: receivedData.rect.top - receivedData.canvasRect.top,
            left: receivedData.rect.left - receivedData.canvasRect.left,
            width: receivedData.rect.width,
            height: receivedData.rect.height,
          });
          this.props.setIsCaptureAreaActive(true);
        },
      });
      setSetVideoArrayBufferResponseListener({
        responseHandler: async (receivedData) => {
          let currentDocumentKey = this.props.currentDocument["document_key"];
          const videoBlob = new Blob([receivedData.buffer], {
            type: "audio/webm;codecs=opus",
          });
          const uploadedVideoUrl = await uploadFile({
            path: `clip_videos/${currentDocumentKey}`,
            file: new File([videoBlob], `${uuidv4()}.webm`),
          });
          // upload base64 img to AWS S3
          const uploadedVideoPosterUrl = await uploadBase64Image({
            path: `clip_video_poster/${currentDocumentKey}`,
            base64: receivedData.posterUrl,
          });
          if (!uploadedVideoPosterUrl || !uploadedVideoUrl) return;
          const savedData = await this.props.editorInstance.save();
          const loaderBlockKey = this.loaderBlockKeys.shift();
          savedData.blocks.forEach((block, index) => {
            if (block.type !== "loader") return;
            if (block.data.blockKey === loaderBlockKey) {
              this.props.editorInstance.blocks.delete(index);
              this.props.editorInstance.blocks.insert(
                "video",
                {
                  videoUrl: uploadedVideoUrl,
                  posterUrl: uploadedVideoPosterUrl,
                },
                undefined,
                index,
                true
              );
              this.props.editorInstance.caret.setToBlock(this.props.editorInstance.blocks.getCurrentBlockIndex() + 1);
            }
          });
        },
      });

      setSnipCaptureIsDoneResponseListener({
        responseHandler: async () => {
          this.setState({
            isSnipCapturing: true,
          });
          // set focus on the iframe.
          sendSetFocusOnIframeRequestToParentWindow();
        },
      });
      window.parent.postMessage({ action: "IFRAME_TO_CONTENT_checkIsSlidInstantLaunched" }, "*");
      sendCheckIsExtensionMediaPermitted();
      setCheckIsExtensionMediaPermittedResponseListener({
        responseHandler: async ({ isExtensionMediaPermitted }) => {
          this.props.setIsExtensionMediaPermitted(isExtensionMediaPermitted);
        },
      });
      setIsExtensionClickedListener({
        responseHandler: async () => {
          if (this.props.isGettingExtensionMediaPermission && !this.props.isExtensionMediaPermitted) {
            if (this.props.isGettingAutoNotesMediaPermission) {
              this.props.setIsGettingAutoNotesMediaPermission(false);
              this.props.setIsGettingAutoNotesMediaPermission(false);
              this.props.showToast(this.props.t("ToastForPermissionGranted", { ns: "VideoNote" }));
              trackEvent({
                eventType: eventTypes.success.AUTO_NOTES_PERMISSION,
              });
            } else if (this.props.isGettingSTTMediaPermission) {
              this.props.setIsGettingSTTMediaPermission(false);
              this.props.showToast(this.props.t("ToastForPermissionGranted", { ns: "VideoNote" }));
              trackEvent({
                eventType: eventTypes.success.SMART_LIVETEXT_PERMISSION,
              });
            } else if (this.props.isGettingClipRecordingMediaPermission) {
              alertExtensionMediaPermissionGranted(<PermissionGrantedAlertMessage />);
              trackEvent({
                eventType: eventTypes.success.VIDEO_SNIPPET_PERMISSION,
              });
              this.props.setIsGettingClipRecordingMediaPermission(false);
            }
            this.props.setIsExtensionMediaPermitted(true);
            this.props.setShowExtensionMediaPermissionModal(false);
            this.props.setIsGettingExtensionMediaPermission(false);
          }
        },
      });
      // Open recent note on extension vdocs new
      if (
        this.props.location.pathname === "/vdocs/new" &&
        this.state.openRecentNote &&
        this.props.cookies.get("SlidCustomSetting") &&
        this.props.cookies.get("SlidCustomSetting").recentNoteDocumentKey
      ) {
        this.props.history.push(`./${this.props.cookies.get("SlidCustomSetting").recentNoteDocumentKey}`);
      }
    }

    sendAmplitudeData(`Enter vdocs page`);
    trackEvent({
      eventType: "Enter video note page",
      eventProperties: this.props.location.pathname.includes("new") ? { noteKey: "new" } : {},
    });

    this.props.setEditorWrapperClassName(styles[`video-document-container`]);
    document.addEventListener("keydown", this.setKeyDownListener.bind(this));
    // DESKTOP -- listen to the Primary window messages
    window.addEventListener("message", this.onMessage);
    // Extension -- listen to the extension's message
    window.addEventListener("message", this.setMessageListenerFromVideo);

    window.addEventListener("message", this.onMobileAppMessage);

    this.props.setClipRecordingCountdownNumber(this.clipRecordingMaxCountdownNumber);
    // When the user comes to vdocs from other page
    if (this.props.applicationType === "web" && this.props.isExtensionInstalled) {
      this.externalPort = connectPortToExtension();
      this.setExternalPortMessageHandler();
    }
    if (this.props.applicationType === "desktop" && localStorage.getItem("slid_user_set_capture_area") === "true") {
      this.setState({
        showDesktopCaptureTooltip: false,
      });
    }
    setCheckIsSlidInstantLaunchedListener({
      responseHandler: async () => {
        this.setState({ isSlidInstantLaunched: true });
      },
    });
    setIsExtensionClickedListener({
      responseHandler: async () => {
        this.setState({ isSlidInstantLaunched: false });
      },
    });

    if (isMobileApp()) {
      //@ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "WEB_TO_APP_requestCropArea",
        })
      );
      //@ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "WEB_TO_APP_checkPhotoLibraryPermission",
        })
      );
    }
  }

  shouldComponentUpdate(nextProps) {
    // don't update component when last active block position is
    if (this.props.editorLastActiveBlockPosition !== nextProps.editorLastActiveBlockPosition) {
      return false;
    }
    return true;
  }

  async componentDidUpdate(prevProps, prevState) {
    const isAuthenticated = await isUserAuthenticated();
    if (this.props.modalOn !== prevProps.modalOn && this.props.applicationType === "desktop") {
      if (this.props.modalOn) {
        this.setState({ isSetCaptureAreaButtonClicked: true });
      } else {
        this.setState({ isSetCaptureAreaButtonClicked: false });
      }
    }
    if (this.props.modalOn !== prevProps.modalOn && this.props.applicationType === "extension") {
      const videoPlaceHolder = document.getElementById("video-placeholder");
      // Modal hides on the video, so hide the video to show modal well
      if (this.props.modalOn) {
        sendHideVideoRequestToParentWindow();
      } else {
        setTimeout(() => {
          sendVideoPlaceholderPositionToParentWindow({
            videoPlaceholderPosition: videoPlaceHolder.getBoundingClientRect(),
          });
        }, 250);
      }
    }
    if (prevProps.applicationType !== this.props.applicationType) {
      // when application type is extension, set response listeners. and open recent document
      if (this.props.applicationType === "extension") {
        sendGoBackExtensionViewerButton();
        setScreenCaptureResponseListener({
          responseHandler: this.captureResponseHandler.bind(this),
        });
        setSetCurrentCaptureAreaResponseListener({
          responseHandler: async (receivedData) => {
            this.props.setCaptureAreaRect({
              top: receivedData.rect.top - receivedData.canvasRect.top,
              left: receivedData.rect.left - receivedData.canvasRect.left,
              width: receivedData.rect.width,
              height: receivedData.rect.height,
            });
            this.props.setIsCaptureAreaActive(true);
          },
        });
        setSetVideoArrayBufferResponseListener({
          responseHandler: async (receivedData) => {
            let currentDocumentKey = this.props.currentDocument["document_key"];
            const videoBlob = new Blob([receivedData.buffer], {
              type: "audio/webm;codecs=opus",
            });
            const uploadedVideoUrl = await uploadFile({
              path: `clip_videos/${currentDocumentKey}`,
              file: new File([videoBlob], `${uuidv4()}.webm`),
            });
            // upload base64 img to AWS S3
            const uploadedVideoPosterUrl = await uploadBase64Image({
              path: `clip_video_poster/${currentDocumentKey}`,
              base64: receivedData.posterUrl,
            });
            if (!uploadedVideoPosterUrl || !uploadedVideoUrl) return;
            const savedData = await this.props.editorInstance.save();
            const loaderBlockKey = this.loaderBlockKeys.shift();
            savedData.blocks.forEach((block, index) => {
              if (block.type !== "loader") return;
              if (block.data.blockKey === loaderBlockKey) {
                this.props.editorInstance.blocks.delete(index);
                this.props.editorInstance.blocks.insert(
                  "video",
                  {
                    videoUrl: uploadedVideoUrl,
                    posterUrl: uploadedVideoPosterUrl,
                  },
                  undefined,
                  index,
                  true
                );
                this.props.editorInstance.caret.setToBlock(this.props.editorInstance.blocks.getCurrentBlockIndex() + 1);
              }
            });
          },
        });

        setSnipCaptureIsDoneResponseListener({
          responseHandler: async () => {
            this.setState({
              isSnipCapturing: true,
            });
            // set focus on the iframe.
            sendSetFocusOnIframeRequestToParentWindow();
          },
        });
        window.parent.postMessage({ action: "IFRAME_TO_CONTENT_checkIsSlidInstantLaunched" }, "*");
        // Open recent note on extension vdocs new
        if (
          this.props.location.pathname === "/vdocs/new" &&
          this.state.openRecentNote &&
          this.props.cookies.get("SlidCustomSetting") &&
          this.props.cookies.get("SlidCustomSetting").recentNoteDocumentKey
        ) {
          this.props.history.push(`./${this.props.cookies.get("SlidCustomSetting").recentNoteDocumentKey}`);
        }
      } else if (this.props.applicationType === "web") {
        // When the user comes to vdocs first (From extension's review note or refresh vdocs page)
        // For production, isExtensionInstalled set first and applicationType set later
        if (this.props.applicationType === "web" && this.props.isExtensionInstalled) {
          this.externalPort = connectPortToExtension();
          this.setExternalPortMessageHandler();
        }
      }
    }
    if (prevProps.currentDocument && this.props.currentDocument && prevProps.currentDocument.document_key !== this.props.currentDocument.document_key) {
      if (this.props.applicationType === "extension") {
        if (this.props.currentDocument) {
          setDefaultSettingCookie({
            property: `recentNoteDocumentKey`,
            value: this.props.currentDocument["document_key"],
          });
        }
      }
    }
    if (prevProps.documents !== this.props.documents) {
      if (!this.props.documents || this.props.documents.length === 0) return;
      // set folder name and folder list
      const newDocumentsPath = { new: this.props.t("NoFolder", { ns: "VideoNote" }) };
      const setNoteFolderName = ({ folder, cumPathName }) => {
        folder.documents.forEach((item) => {
          if (item["is_folder"]) {
            let newCumPathName = cumPathName ? `${cumPathName} / ${item["title"]}` : `${item["title"]}`;
            setNoteFolderName({
              folder: item,
              cumPathName: newCumPathName,
            });
          } else {
            let newCumPathName = cumPathName ? `${cumPathName}` : this.props.t("NoFolder", { ns: "VideoNote" });
            newDocumentsPath[item["document_key"]] = newCumPathName;
          }
        });
      };
      setNoteFolderName({
        folder: {
          documents: this.props.documents,
        },
        cumPathName: null,
      });
      this.setState({ currentCumFolderName: this.props.currentDocument ? newDocumentsPath[this.props.currentDocument["document_key"]] : newDocumentsPath["new"] });
      // set guest mode alert based on documents length
      if (this.props.applicationType === "desktop") {
        if (!isAuthenticated) {
          if (this.isGuestModeAlertShown) return;
          // Force guest mode alert for desktop
          this.DESKTOP_showGuestModeAlert();
          this.isGuestModeAlertShown = true;
        }
      }
    }
    // when the user is signed out, or auth token expires, we detect it in app.js but we wanna fire the alert in VideoDocumentEditor.js
    if (prevProps.showGuestModeAlert !== this.props.showGuestModeAlert) {
      if (this.props.showGuestModeAlert === false) return;
      if (this.isGuestModeAlertShown) return;
      this.isGuestModeAlertShown = true;
      this.DESKTOP_showGuestModeAlert();
    }
    if (prevProps.extensionVersion !== this.props.extensionVersion) {
      // For web capture
      if (this.props.applicationType === "web") {
        // extensionVersion is changed after this.setExternalPortMessageHandler()
        const isExtensionVersionAvailable = checkIfVersionIsAvailable({
          requiredVersion: WEB_CAPTURE_AVAILABLE_EXTENSION_VERSION,
          currentVersion: this.props.extensionVersion.version,
        });
        if (isExtensionVersionAvailable && this.externalPort) {
          this.setState({
            isWebCaptureAvailable: true,
          });
        }
      }
    }
    if (prevProps.isExtensionInstalled !== this.props.isExtensionInstalled) {
      // Except production, isExtensionInstalled set later than applicationType
      if (this.props.applicationType === "web" && this.props.isExtensionInstalled) {
        this.externalPort = connectPortToExtension();
        this.setExternalPortMessageHandler();
      }
    }
    if (prevProps.isCaptureAreaSetForSnipCapture !== this.props.isCaptureAreaSetForSnipCapture) {
      if (this.props.isCaptureAreaSetForSnipCapture) {
        this.setCanvasForSnipCapture();
      }
    }
    if (prevProps.isCaptureAreaSetForOneClickCapture !== this.props.isCaptureAreaSetForOneClickCapture) {
      if (this.props.isCaptureAreaSetForOneClickCapture) {
        this.setCanvasForOneClickCapture();
      }
    }
    if (prevProps.iframePort !== this.props.iframePort) {
      if (this.props.userData?.clip_cnt === 0) {
        // If the user had no capture before, should show how to slid desktop until they capture first.
        this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      } else if (this.props.userData) {
        // Check if the user have not been shown the onboarding
        this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_CHECK_SHOULD_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        });
      }
    }
    if (prevState.isSlidInstantLaunched !== this.state.isSlidInstantLaunched) {
      if (this.state.isSlidInstantLaunched) {
        trackEvent({
          eventType: "Enter video note page with instant launch",
        });
      }
    }
    if (prevProps.isTabletAppOpenedInSplitView !== this.props.isTabletAppOpenedInSplitView) {
      if (!this.props.isTabletAppOpenedInSplitView) {
        if (this.props.currentDocument?.document_key) this.props.history.push(`/docs/${this.props.currentDocument.document_key}`);
        else this.props.history.push(`/docs/new`);
      }
    }

    if (prevProps.currentVideo !== this.props.currentVideo) {
      if (this.props.applicationType === "extension" && !this.props.currentVideo) sendVideoInfoRequestToParentWindow();
    }
    // When editor is touched, showPlaceholder became false
    if (prevProps.showPlaceholder !== this.props.showPlaceholder && !this.props.showPlaceholder && isTabletApp()) {
      trackEvent({ eventType: "Close TAKING SCREENSHOT in video note page" });
      this.setState({ shouldShowTabletCaptureGuideTooltip: false });
    }
    if (prevProps.showExtensionMediaPermissionModal !== this.props.showExtensionMediaPermissionModal) {
      if (this.props.showExtensionMediaPermissionModal) {
        showCustomModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
          customComponentContent: <ExtensionMediaPermissionModal />,
        });
      } else {
        this.props.closeModal();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.setKeyDownListener.bind(this));
    window.removeEventListener("message", this.onMessage);
    window.removeEventListener("message", this.setMessageListenerFromVideo);
    window.removeEventListener("message", this.onMobileAppMessage);
  }

  onMouseEnterHelpButton(origin) {
    if (!this.props.isHelpMenuOpen && !this.state.isHelpButtonHoveredOnce) {
      trackEvent({
        eventType: origin === "help-button" ? eventTypes.hover.HELP_MENU : eventTypes.hover.AI_SLIDDY_BUTTON,
        eventProperties: {
          from: getEventLocationMode(),
        },
      });
      this.setState({ isHelpButtonHoveredOnce: true });
    }
    this.props.setIsHelpMenuOpen(true);
  }

  setExternalPortMessageHandler() {
    if (this.externalPort) {
      this.externalPort.onMessage.addListener((message) => {
        if (message) {
          const { action, data } = message;
          switch (action) {
            case "BACK_TO_WEB_getCurrentVersion":
              const version = data.version;
              this.props.setExtensionVersion(version);
              break;
            case "BACK_TO_WEB_sendCaptureImg":
              this.captureResponseHandler(data);
              break;
            default:
              return;
          }
        }
      });
      try {
        this.externalPort.postMessage({
          action: "WEB_TO_BACK_getCurrentVersion",
        });
      } catch (e) {
        // If web - extension background connection is lost, reload to connect again.
        window.location.reload();
      }
    }
  }

  async captureResponseHandler(receivedData) {
    if (this.props.applicationType === "web") {
      if (this.props.videoPlayerRef) {
        // For youtube and vimeo videos
        receivedData = {
          ...receivedData,
          captureTime: this.props.videoPlayerRef.getCurrentTime(),
        };
      }
    }

    const insertCapturedImage = async (currentDocumentKey) => {
      const { blocks, caret } = this.props.editorInstance;
      const { getCurrentBlockIndex, insert: insertBlock, delete: deleteBlock, getBlockByIndex } = blocks;
      const { setToBlock: setCaretToBlock } = caret;

      /**
       * editorLastActiveBlockPosition is updated in EditorComponent.tsx for store last index of the block when user blurs the editor.
       * When document key is changed by clicking other note in the note list, editorLastActiveBlockPosition becomes undefined so that new image block is inserted at the end of the document.
       */
      let imageInsertIndex;
      let firstSLTBlockIndex = -1;
      const lastBlock = blocks.getBlockByIndex(blocks.getBlocksCount() - 1);
      if (this.props.isSTTActive) {
        if (lastBlock && lastBlock.name !== "smartLiveText") {
          imageInsertIndex = blocks.getBlocksCount();
        } else {
          const noteData = await this.props.editorInstance.save();
          noteData.blocks.forEach((block, index) => {
            if (block.type === "smartLiveText" && firstSLTBlockIndex === -1) {
              imageInsertIndex = index;
              firstSLTBlockIndex = index;
              return;
            }
          });
        }
      } else if (this.props.editorLastActiveBlockPosition !== undefined) {
        imageInsertIndex = this.props.editorInstance.blocks.getCurrentBlockIndex() === -1 ? this.props.editorLastActiveBlockPosition + 1 : undefined;
      } else {
        this.props.editorInstance.caret.setToLastBlock("end", 0);
      }

      setTimeout(() => {
        const element = document.getElementById("slid-editor-js");
        element && element.focus();
        this.setState({
          isCapturing: true,
          isSnipCapturing: true,
        });

        insertBlock(
          "image",
          {
            rawSrc: receivedData.captureImgBase64,
            timestamp: receivedData.captureTime,
            type: "manualCapture",
            setClipData: async () => {
              return await this.getImageBlockData(receivedData, currentDocumentKey);
            },
          },
          undefined,
          imageInsertIndex,
          true
        );
        // delete previous block if it's empty
        const previousBlockIndex = getCurrentBlockIndex() - 1;
        if (isPreviousBlockEmpty(previousBlockIndex)) {
          try {
            deleteBlock(previousBlockIndex);
          } catch (e) {
            // when focus is lost,
            // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
            // will happen.
            // ignore this for now. it's editor js error.
            // https://github.com/codex-team/editor.js/pull/1218
          }
        }
        const nextBlockIndex = getCurrentBlockIndex() + 1;
        if (!isPreviousBlockEmpty(nextBlockIndex)) {
          insertBlock();
        }
        setCaretToBlock(getCurrentBlockIndex());
        if (getBlockByIndex(getCurrentBlockIndex() - 1)) {
          getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView();
        }
        this.setState({
          isCapturing: false,
          isSnipCapturing: false,
        });
        sendRemoveSnipCaptureAreaRequestToParentWindow();
      });
    };
    // capture area is not selected properly
    if (receivedData.captureImgBase64 === "data:,") {
      this.showSelectAreaPopup();
      return;
    }
    if (!this.props.editorInstance) {
      this.setState({
        isCapturing: false,
        isSnipCapturing: false,
      });
      return;
    }
    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    this.props.history.replace(`./${currentDocumentKey}`);
    insertCapturedImage(currentDocumentKey);
  }

  async onMobileAppMessage(event) {
    if (event.data && typeof event.data === "string") {
      try {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "APP_TO_WEB_sendSavedCropArea":
            this.props.setTabletDefaultCropArea(JSON.parse(data.payload));
            break;
          case "APP_TO_WEB_photoLibraryPermissionResult":
            this.props.closeModal();
            if (data.payload !== "granted") {
              showPhotoLibraryPermissionRequiredModal({
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
                reactNativeWebView: window.ReactNativeWebView,
              });
            }
            break;
          case "APP_TO_WEB_sendImage":
            if (!this.props.tabletDefaultCropArea || this.props.editImageCropArea) {
              this.props.setIsImageCroppingModalOpen(true); // Open Image Crop Modal
              this.props.setShouldUpdateImageByCrop(false);
              this.props.setCroppingImageBlockData(null);
              await this.props.setImageCroppingModalImageSource(data.payload);
              return;
            }

            this.captureResponseHandlerWithoutTimestamp(data.payload);
            break;
          default:
            return;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  onMessage(event) {
    /*
    Listen to the messages from the Primary window of Slid Desktop
    */
    switch (event.data.type) {
      case "PRIMARY_TO_IFRAME_ACTIVATE_CAPTURE_BUTTON":
        this.setState({ isDesktopCaptureAreaSet: true });
        this.setState({ isDesktopCaptureAreaSetting: false });
        break;
      case "PRIMARY_TO_IFRAME_SPIN_CAPTURE_BUTTON":
        this.setState({ isCapturing: true });
        break;
      case "PRIMARY_TO_IFRAME_STOP_SPINNING_CAPTURE_BUTTON":
        this.setState({ isCapturing: false });
        break;
      case "PRIMARY_TO_IFRAME_DEACTIVATE_CAPTURE_BUTTON":
        this.setState({ isDesktopCaptureAreaSet: false });
        break;
      case "PRIMARY_TO_IFRAME_SEND_IMAGE":
        this.setState({
          isCapturing: false,
        });
        const imgBase64 = event.data.payload;
        this.captureResponseHandlerWithoutTimestamp(imgBase64);
        break;
      case "PRIMARY_TO_IFRAME_CAPTURE_FAILED":
        this.showDesktopGuideToRestart();
        this.setState({
          isCapturing: false,
        });
        break;
      case "PRIMARY_TO_IFRAME_SEND_SELECTABLE_PROCESSES":
        const sources = event.data.payload;
        this.props.setSelectableProcesses(sources);
        break;
      case "PRIMARY_TO_IFRAME_CANCEL_SETTING_CAPTURE_AREA_FIRST":
        this.setState({ isDesktopCaptureAreaSetting: false });
        break;
      case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_UNAVAILABLE":
        this.setState({ isDesktopCaptureAreaSetting: false, isDesktopCaptureAreaSet: false });
        showFailToLoadWindowModal(
          {
            showModal: this.props.showModal,
            closeModal: this.props.closeModal,
            history: this.props.history,
          },
          this.openShowSelectWindowModal.bind(this)
        );
        break;
      case "PRIMARY_TO_IFRAME_SELECTED_PROCESS_TERMINATED":
        this.setState({ isDesktopCaptureAreaSet: false });
        break;
      case "PRIMARY_TO_IFRAME_SEND_ADI":
        initEventTracking(event.data.payload);
        break;
      case "PRIMARY_TO_IFRAME_handleError":
        this.setState({ isDesktopCaptureAreaSetting: false, isDesktopCaptureAreaSet: false });
        this.openErrorOnDesktopModal();
        break;
      case "PRIMARY_TO_IFRAME_showStopCapturingOnDesktopModal":
        showStopCapturingOnDesktopModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
        });
        break;
      case "PRIMARY_TO_IFRAME_sendShouldShowScaleFactorModal":
        this.setState({ shouldShowScaleFactorModal: event.data.payload });
        break;
      case "PRIMARY_TO_IFRAME_trackToggleNSnippersByShortcut":
        trackEvent({
          eventType: "Use shortcut",
          eventProperties: {
            type: `snip capture`,
            isCaptureAreaFixed: this.state.isDesktopCaptureAreaSet,
          },
        });
        break;
      default:
        return;
    }
  }

  openShowSelectWindowModal = () => {
    if (this.state.shouldShowScaleFactorModal) {
      showCustomModal({
        showModal: this.props.showModal,
        closeModal: this.props.closeModal,
        customComponentContent: <ScaleFactorChangeModal />,
      });
      return;
    }
    // Turn off STT if it's on
    if (this.props.isSTTToggledOn) {
      this.props.setIsSTTToggledOn(false);
      this.props.setStopDesktopSTT();
      this.props.setShowSmartLiveTextView(false);
    }
    showCustomModal({
      showModal: this.props.showModal,
      closeModal: this.props.closeModal,
      customComponentContent: (
        <SelectWindowModal
          setIsDesktopCaptureAreaSetting={(isDesktopCaptureAreaSetting) => {
            this.setState({
              isDesktopCaptureAreaSetting,
            });
          }}
          setIsDesktopCaptureAreaSet={(isDesktopCaptureAreaSet) => {
            this.setState({
              isDesktopCaptureAreaSet,
            });
          }}
          setShowDesktopCaptureTooltip={(showDesktopCaptureTooltip) => {
            this.setState({
              showDesktopCaptureTooltip,
            });
          }}
        />
      ),
    });
  };

  openErrorOnDesktopModal() {
    showErrorOnDesktopModal({
      showModal: this.props.showModal,
      closeModal: this.props.closeModal,
    });
  }

  async openChannelTalk() {
    this.props.bootChannelTalk();
  }

  /**
   * This method is for receiving keydown event from video which is in slid_extension.
   * @param {event} message
   */
  setMessageListenerFromVideo(message) {
    if (message.data.action === "CONTENT_TO_IFRAME_sendKeyboardShortcutEvent") {
      const shortCutEvent = new Event("keydown");
      const { code, altKey, ctrlKey, metaKey, shiftKey } = message.data.data.event;
      shortCutEvent.code = code;
      shortCutEvent.altKey = altKey;
      shortCutEvent.ctrlKey = ctrlKey;
      shortCutEvent.metaKey = metaKey;
      shortCutEvent.shiftKey = shiftKey;
      this.setShortcutListener(shortCutEvent);
    }
  }

  sendMessageToPrimary(message) {
    /*
    Sends messages to the primary window of Slid Desktop
    */
    if (this.props.iframePort) {
      this.props.iframePort.postMessage(message);
      return true;
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
      });
      if (findLatestOfTwoVersions(this.props.desktopVersion, "1.3.0") === this.props.desktopVersion) {
        // show popup to restart the app - done by the caller of sendMessageToPrimary
      } else {
        this.showGuideMessageForDesktopCaptureFailure();
      }
      return false;
    }
  }

  showGuestModeAlert({ shouldForceSignup }) {
    Sweetalert.fire({
      target: `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "비회원으로 이용 중입니다!" : "You're in guest mode!",
      html:
        this.props.lang === "ko"
          ? `
              비회원은 노트를 최대 3개까지 작성할 수 있습니다. <br/>
              로그인을 하면 <b>무제한으로 이용하실 수 있습니다.<b/>
          `
          : `
              Guest mode can write up to 3 notes. <br/>
              Sign-in to unlock <b>unlimited notes.<b/>
          `,
      icon: "info",
      confirmButtonText: this.props.lang === "ko" ? "로그인" : "Sign-in",
      showCancelButton: !shouldForceSignup,
      cancelButtonText: this.props.lang === "ko" ? "닫기" : "Close",
      allowOutsideClick: !shouldForceSignup,
    }).then((result) => {
      if (result.isConfirmed) {
        switch (this.props.applicationType) {
          case "extension":
            window.open(`/sign-in`);
            Sweetalert.fire({
              target: `.${styles[`video-document-container`]}`,
              heightAuto: false,
              customClass: {
                container: "position-absolute",
              },
              title: this.props.lang === "ko" ? "새 탭에서 로그인을 해주세요!" : "Sign-in in the new tab!",
              html:
                this.props.lang === "ko"
                  ? `
                  새 탭에서 로그인 후, <br/>
                  슬리드를 닫고 다시 실행해주세요. <br/>
                  * 새 탭이 뜨지 않으면 위 영상을 참고하세요.
              `
                  : `
                  After signing-in in the new tab, <br/>
                  Close and relaunch Slid. <br/>
              `,
              imageUrl: "/src/gif/slid_allow_popup.gif",
              imageWidth: "100%",
              imageAlt: "Popup allow guide image",
              showConfirmButton: false,
              allowOutsideClick: false,
            });
            return;
          case "web":
            this.props.history.push(`/sign-in`);
            return;
          default:
            return;
        }
      }
    });
  }

  DESKTOP_showGuestModeAlert() {
    Sweetalert.fire({
      target: `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "로그인을 먼저 해주세요!" : "Please sign-in!",
      html:
        this.props.lang === "ko"
          ? `
              아래 '로그인' 버튼을 누르면 <br/>
              브라우저에서 로그인 창이 열립니다.
          `
          : `
              Click the button below to sign-in. <br/>
              Sign-in page will be opened in the browser.
          `,
      icon: "info",
      confirmButtonText: this.props.lang === "ko" ? "로그인" : "Sign-in",
      showCancelButton: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_OPEN_URL",
          payload: `${SLID_WEB_APP_URL}/desktop_auth`,
        });
        ReactSweetalert.fire({
          target: `.${styles[`video-document-container`]}`,
          heightAuto: false,
          customClass: {
            container: "position-absolute",
          },
          title: this.props.lang === "ko" ? "새 창에서 로그인을 해주세요!" : "Sign-in in the browser!",
          html: (
            <>
              <p>
                {this.props.lang === "ko" ? (
                  <>
                    브라우저에서 로그인창이 뜨지 않으면, <br />
                    아래 주소를 직접 복사해 크롬 주소창에 붙여넣어 주세요.
                  </>
                ) : (
                  <>
                    If sign-in page is not opened, <br />
                    copy and paste the URL to the Chrome.
                  </>
                )}
              </p>
              <div>
                <InputGroup>
                  <FormControl ref={this.desktopAuthLinkInputRef} value={`${SLID_WEB_APP_URL}/desktop_auth`} readOnly={true} />
                  <Button
                    variant="light"
                    onClick={(event) => {
                      this.onClickCopyBtn(event.target);
                    }}
                  >
                    Copy
                  </Button>
                </InputGroup>
              </div>
              <div className={`mt-3`}>
                <a
                  href={`/#`}
                  onClick={() => {
                    this.openChannelTalk();
                  }}
                >
                  {this.props.lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
                </a>
              </div>
            </>
          ),
          showConfirmButton: false,
          allowOutsideClick: false,
        });
      }
    });
  }

  onClickCopyBtn(buttonElement) {
    if (!this.desktopAuthLinkInputRef.current) return;
    try {
      // copy to clipboard
      this.desktopAuthLinkInputRef.current.select();
      document.execCommand("copy");
      // Update copy button
      const copiedText = "Copied!";
      const originalText = buttonElement.innerText;
      buttonElement.innerText = copiedText;
      buttonElement.style.color = "#5A95F8";
      // reset
      setTimeout(() => {
        buttonElement.innerText = originalText;
        buttonElement.style.color = "";
      }, 3000);
    } catch (err) {
      // unable to copy
    }
  }

  async captureResponseHandlerWithoutTimestamp(imgBase64) {
    if (this.props.userData && this.props.userData.payment === "starter") {
      await this.props.fetchQuotaInfo();
    }

    const insertCapturedImage = async (currentDocumentKey) => {
      const { blocks, caret } = this.props.editorInstance;
      const { getCurrentBlockIndex, insert: insertBlock, delete: deleteBlock, getBlockByIndex } = blocks;
      const { setToBlock: setCaretToBlock } = caret;

      /**
       * editorLastActiveBlockPosition is updated in EditorComponent.tsx for store last index of the block when user blurs the editor.
       * When document key is changed by clicking other note in the note list, editorLastActiveBlockPosition becomes undefined so that new image block is inserted at the end of the document.
       */
      let imageInsertIndex;
      let waitTime = 10;
      if (this.props.editorLastActiveBlockPosition !== undefined) {
        imageInsertIndex = this.props.editorInstance.blocks.getCurrentBlockIndex() === -1 ? this.props.editorLastActiveBlockPosition + 1 : undefined;
      } else {
        waitTime = 200; // wait bit longer when no last active position is available, to set cursor (at least 20ms)
        this.props.editorInstance.caret.setToLastBlock("end", 0);
      }

      setTimeout(async () => {
        const element = document.getElementById("slid-editor-js");
        element && element.focus();
        this.setState({
          isCapturing: true,
          isSnipCapturing: true,
        });
        if (isTabletApp()) {
          const croppedImgBase64 = await this.cropImageInTablet(imgBase64);
          insertBlock(
            "image",
            {
              rawSrc: croppedImgBase64,
              type: "manualCapture",
              setClipData: async () => {
                return await this.getImageBlockDataWithoutTimestamp(croppedImgBase64, currentDocumentKey, imgBase64);
              },
            },
            undefined,
            imageInsertIndex,
            true
          );
        } else {
          insertBlock(
            "image",
            {
              rawSrc: imgBase64,
              type: "manualCapture",
              setClipData: async () => {
                return await this.getImageBlockDataWithoutTimestamp(imgBase64, currentDocumentKey);
              },
            },
            undefined,
            imageInsertIndex,
            true
          );
        }

        // delete previous block if it's empty
        const previousBlockIndex = getCurrentBlockIndex() - 1;
        if (isPreviousBlockEmpty(previousBlockIndex)) {
          try {
            deleteBlock(previousBlockIndex);
          } catch (e) {
            // when focus is lost,
            // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
            // will happen.
            // ignore this for now. it's editor js error.
            // https://github.com/codex-team/editor.js/pull/1218
          }
        }
        const nextBlockIndex = getCurrentBlockIndex() + 1;
        if (!isPreviousBlockEmpty(nextBlockIndex)) {
          insertBlock();
        }
        setCaretToBlock(getCurrentBlockIndex());
        if (getBlockByIndex(getCurrentBlockIndex() - 1)) {
          getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        this.setState({
          isCapturing: false,
          isSnipCapturing: false,
        });
        sendRemoveSnipCaptureAreaRequestToParentWindow();
      }, waitTime);
    };
    if (!this.props.editorInstance) {
      this.setState({
        isCapturing: false,
        isSnipCapturing: false,
      });
      return;
    }
    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return;
    this.props.history.replace(`./${currentDocumentKey}`);
    insertCapturedImage(currentDocumentKey);
  }

  cropImageInTablet(src) {
    return new Promise((resolve) => {
      let { x, y, width, height } = this.props.tabletDefaultCropArea;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.onload = async () => {
        // set canvas size
        canvas.width = Math.floor(width);
        canvas.height = Math.floor(height);
        // draw the image
        ctx.drawImage(image, Math.floor(x), Math.floor(y), Math.floor(width), Math.floor(height), 0, 0, Math.floor(width), Math.floor(height));

        let croppedBase64 = canvas.toDataURL();
        resolve(croppedBase64);
      };
      image.src = src;
    });
  }

  async getImageBlockDataWithoutTimestamp(imgBase64, currentDocumentKey, fullImageBase64 = null) {
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: imgBase64,
    });
    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;
    const clipRegisterResponse = await this.props.registerClip({
      imgSrc: uploadedImageSrc,
      documentKey: currentDocumentKey,
      clipEndPoint: "capture-clip",
    });
    if (!clipRegisterResponse) return;

    let imgObj = {
      src: uploadedImageSrc,
      fullSizeImageSrcForCropping: uploadedImageSrc, // For supporting image cropping in desktop app
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };
    // For tablet app, we need to upload full image also.

    if (isTabletApp()) {
      const uploadedFullImageSrc = await uploadBase64Image({
        path: `capture_images/${currentDocumentKey}`,
        base64: fullImageBase64,
      });
      imgObj = {
        ...imgObj,
        fullSizeImageSrcForCropping: uploadedFullImageSrc,
      };
    }
    return imgObj;
  }

  async getImageBlockData(receivedData, currentDocumentKey) {
    /*
      returns image block data: this.data
    */
    let videoInfo = null;
    let hasVideo = true;

    // retry getting video info from extension
    if (this.props.applicationType === "extension" && !this.props.currentVideo) {
      sendVideoInfoRequestToParentWindow();
    }

    // upload base64 img to AWS S3
    const uploadedImageSrc = await uploadBase64Image({
      path: `capture_images/${currentDocumentKey}`,
      base64: receivedData.captureImgBase64,
    });

    if (!uploadedImageSrc || typeof uploadedImageSrc !== "string") return;

    if (this.props.currentVideo) {
      videoInfo = {
        videoType: this.props.currentVideo.videoType,
        videoUnique: this.props.currentVideo.videoUniqueKey,
        videoOriginUrl: this.props.currentVideo.videoUrl,
        websiteOriginUrl: this.props.currentVideo.originUrl,
        videoKey: this.props.currentVideo.videoKey,
      };

      // if the video key is not here, the video was not yet registered, so register it
      if (!videoInfo.videoKey) {
        const videoRegisterInfo = await this.props.registerVideo({
          videoInfo: this.props.currentVideo,
          documentKey: currentDocumentKey,
        });

        if (videoRegisterInfo && !videoRegisterInfo.error_message) {
          videoInfo.videoKey = videoRegisterInfo["video_key"];
          await this.props.updateCurrentVideo({
            videoKey: videoRegisterInfo["video_key"],
          });
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
        }
      }
    }

    // to catch video registration issues in extension
    if (this.props.applicationType === "extension") {
      if (!this.props.currentVideo) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video is not set in redux, when saving a capture in extension, no attempt to register video was made");
        });
      }
      if (this.props.currentVideo && !this.props.currentVideo.videoKey) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video key is not set in redux current-video object when saving a capture in extension. Attempt to register video was made but failed");
        });
      }
    }

    // at this point if we still don't have video key, we won't save video info in img block
    if (!videoInfo || !videoInfo.videoKey) hasVideo = false;

    // save the metadata to the database
    const clipRegisterResponse = await this.props.registerClip({
      imgSrc: uploadedImageSrc,
      clipTimestamp: receivedData.captureTime,
      documentKey: currentDocumentKey,
      videoKey: hasVideo ? videoInfo.videoKey : null,
      clipEndPoint: "capture-clip",
    });
    if (clipRegisterResponse.error_message) return;

    // return the image block data
    const imageBlockData = {
      src: uploadedImageSrc,
      timestamp: receivedData.captureTime, // `receivedData` is only available in extension and web app video note
      documentKey: currentDocumentKey,
      clipKey: clipRegisterResponse["clip_key"],
      type: "manualCapture",
    };
    if (hasVideo) imageBlockData.videoInfo = videoInfo;
    return imageBlockData;
  }

  async setKeyDownListener(event) {
    this.setShortcutListener(event);
    switch (event.key) {
      case "Escape":
        sendCancelCaptureAreaRequestToParentWindow();
        this.setState({
          isCapturing: false,
        });
        break;
      default:
        break;
    }
  }

  setShortcutListener(event) {
    const code = event.code;
    const states = {
      alt: event.altKey,
      ctrl: event.ctrlKey,
      meta: event.metaKey,
      shift: event.shiftKey,
    };
    switch (code) {
      // short cut for manual capture (Cmd + / or Alt + /)
      case "Slash":
        if (states.alt || states.meta) {
          event.preventDefault();
          if (this.state.isCapturing) return;
          this.manualCapture();
          sendAmplitudeData(`Use shortcut`, {
            type: `one-click capture`,
          });
        }
        break;
      // short cut for snip capture (option + A or Alt + A)
      case "KeyA":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Use shortcut",
            eventProperties: {
              type: `snip capture`,
            },
          });
          if (this.props.applicationType === "extension") {
            if (event.altKey) {
              sendSetSnipCaptureAreaRequestToParentWindow(true);
            }
          } else if (this.props.applicationType === "web") {
            if (!this.props.isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
              });
            }
            if (this.state.isWebCaptureAvailable) {
              this.props.setIsCaptureAreaSetForSnipCapture(true);
            }
          }
        }
        break;
      // short cut for snip capture (option + X or Alt + X)
      case "KeyX":
        if (states.alt) {
          event.preventDefault();
          trackEvent({
            eventType: "Shortcut for setting capture area",
          });
          if (this.props.applicationType === "extension") {
            sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
          } else if (this.props.applicationType === "web") {
            if (!this.props.isExtensionInstalled) {
              return showDownloadExtensionModal({
                showModal: this.props.showModal,
                closeModal: this.props.closeModal,
              });
            }
            if (this.state.isWebCaptureAvailable) {
              this.props.setIsCaptureAreaSetForOneClickCapture(true);
            }
          }
        }
        break;
      default:
        return;
    }
  }

  async manualCapture() {
    sendAmplitudeData(`SLID_1_CAPTURE_IMAGE`);
    if (this.props.applicationType) {
      // Sometimes applicationType is null when the event is occurred in desktop
      trackEvent({
        eventType: "Click 1-click capture",
      });
    }
    if (!this.props.confirmPrivilege(SlidFeatures.oneClickCapture)) return this.props.showInsufficientPrivilegeModal();
    if (this.props.applicationType === "extension") {
      this.setState({
        isCapturing: true,
      });
      screenCapture({
        applicationType: this.props.applicationType,
      });
    } else if (this.props.applicationType === "desktop") {
      // for v2.0.3+
      if (findLatestOfTwoVersions(this.props.desktopVersion, "2.5.2") === this.props.desktopVersion) {
        if (this.isGuestModeAlertShown) return;
        // here the button is blue
        if (this.state.isDesktopCaptureAreaSet) {
          const didSendMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_CAPTURE", payload: null });
          if (didSendMessageToPrimary) {
            this.setState({ isCapturing: true });
          } else {
            this.showDesktopGuideToRestart();
          }
        } else {
          this.openShowSelectWindowModal();
        }
      } else if (findLatestOfTwoVersions(this.props.desktopVersion, "2.0.3") === this.props.desktopVersion) {
        if (this.isGuestModeAlertShown) return;
        // here the button is blue
        if (this.state.isDesktopCaptureAreaSet) {
          const didSendMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_CAPTURE", payload: null });
          if (didSendMessageToPrimary) {
            this.setState({ isCapturing: true });
          } else {
            this.showDesktopGuideToRestart();
          }
        } else {
          // here the button is gray
          const didSendMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_SET_CAPTURE_AREA", payload: null });
          if (!didSendMessageToPrimary) this.showDesktopGuideToRestart();
          localStorage.setItem("slid_user_set_capture_area", "true");
        }
      }
      // for v1.3.0+
      else if (findLatestOfTwoVersions(this.props.desktopVersion, "1.3.0") === this.props.desktopVersion) {
        if (this.isGuestModeAlertShown) return;
        // here the button is blue
        if (this.state.isDesktopCaptureAreaSet) {
          const didSendMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_CAPTURE", payload: null });
          if (didSendMessageToPrimary) {
            this.setState({ isCapturing: true });
          } else {
            this.showDesktopGuideToRestart();
          }
        } else {
          // here the button is gray
          const didSendMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_SHOW_CAPTURE_AREA", payload: null });
          if (!didSendMessageToPrimary) this.showDesktopGuideToRestart();
        }
      } else {
        // for versions ealier than v1.3.0
        if (!this.props.selectedSourceData) {
          this.showGuideMessageForDesktopCaptureFailure();
          return;
        }
        // in case an attempt to capture using a shortcut is made
        if (this.isGuestModeAlertShown) return;
        const didSendMessageToPrimary = this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_CAPTURE",
          payload: {
            selectedWindowId: this.props.selectedSourceData.selectedSourceId,
            selectedWindowName: this.props.selectedSourceData.selectedSourceName,
            selectedWindowRect: this.props.desktopDefaultCaptureRect,
            captureRect: this.props.desktopCaptureRect,
          },
        });
        if (didSendMessageToPrimary) {
          this.setState({ isCapturing: true });
        } else {
          this.showGuideMessageForDesktopCaptureFailure();
        }
      }
    } else if (this.props.applicationType === "web") {
      if (!this.props.isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
        });
      }
      if (this.state.isWebCaptureAvailable) {
        const videoElement =
          document.querySelector(".slid-video-player video") || // normal video
          document.querySelector(".slid-video-player iframe") || // youtube or vimeo
          document.querySelector(".slid-video-player-iframe"); // iframe video
        const rect = {
          top: 2.5,
          left: 2.5,
          width: videoElement?.getBoundingClientRect().width - 5,
          height: videoElement?.getBoundingClientRect().height - 5,
        };
        try {
          this.externalPort.postMessage({
            action: "WEB_TO_BACK_captureCurrentArea",
            data: {
              captureRect: this.props.captureRect ? this.props.captureRect : rect,
              videoContainerRect: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect(),
              devicePixelRatio: window.devicePixelRatio,
            },
          });
        } catch (e) {
          // If web - extension background connection is lost, reload to connect again.
          window.location.reload();
        }
      } else {
        showWantToContinueCapturingModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
        });
      }
    }
  }

  showDesktopGuideToRestart() {
    ReactSweetalert.fire({
      icon: "info",
      target: `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "슬리드를 재시작하세요!" : "Restart Slid!",
      html: (
        <>
          {this.props.lang === "ko" ? (
            <p>
              앗! 서버에 연결할 수 없습니다. 😅
              <br />
              슬리드를 재시작하세요.
            </p>
          ) : (
            <p>
              Oops! We could not connect to the server. <br />
              Please restart slid.
            </p>
          )}
          <div className={`mt-3`}>
            <a
              href={`/#`}
              onClick={() => {
                this.openChannelTalk();
              }}
            >
              {this.props.lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
            </a>
          </div>
        </>
      ),
      showConfirmButton: true,
      confirmButtonText: this.props.lang === "ko" ? "재시작" : "Restart",
      showCancelButton: true,
      cancelButtonText: this.props.lang === "ko" ? "닫기" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        window.parent.postMessage("IFRAME_TO_PRIMARY_RESTART", "*");
      }
    });
  }

  showGuideMessageForDesktopCaptureFailure() {
    ReactSweetalert.fire({
      icon: "info",
      target: `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "캡처 도중 오류" : "Selected app not available!",
      html: (
        <>
          {this.props.lang === "ko" ? (
            <p>
              앗! 선택된 프로그램을 찾을 수 없습니다. 😅
              <br />
              슬리드를 재시작해 프로그램을 다시 선택해주세요.
            </p>
          ) : (
            <p>Oops! There was an error capturing, restarting Slid may help.</p>
          )}
          <div className={`mt-3`}>
            <a
              href={`/#`}
              onClick={() => {
                this.openChannelTalk();
              }}
            >
              {this.props.lang === "ko" ? <>도움이 필요하신가요?</> : <>Need help?</>}
            </a>
          </div>
        </>
      ),
      showConfirmButton: true,
      confirmButtonText: this.props.lang === "ko" ? "선택" : "Select",
      showCancelButton: true,
      cancelButtonText: this.props.lang === "ko" ? "닫기" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_LAUNCH_PROMPT",
          payload: null,
        });
      }
    });
  }

  startCountdown() {
    if (this.props.clipRecordingCountdownNumber <= 0) {
      this.clipRecordStop();
      return;
    }
    this.props.setClipRecordingCountdownNumber(this.props.clipRecordingCountdownNumber - 1);
  }

  async insertVideoLoader() {
    const { getCurrentBlockIndex, insert } = this.props.editorInstance.blocks;
    // check current document
    if (!this.props.currentDocument) {
      const createdDocument = await this.props.createDocument({ origin: "vdocs" });
      if (createdDocument.error_message) return;
    }

    /**
     * editorLastActiveBlockPosition is updated in EditorComponent.tsx for store last index of the block when user blurs the editor.
     * When document key is changed by clicking other note in the note list, editorLastActiveBlockPosition becomes undefined so that new video block is inserted at the end of the document.
     */
    let videoLoaderInsertIndex;
    if (this.props.editorLastActiveBlockPosition !== undefined) {
      videoLoaderInsertIndex = this.props.editorInstance.blocks.getCurrentBlockIndex() === -1 ? this.props.editorLastActiveBlockPosition + 1 : undefined;
    } else {
      this.props.editorInstance.caret.setToLastBlock("end", 0);
    }
    const loaderBlockKey = uuidv4();

    // setTimeout(() => {
    this.loaderBlockKeys.push(loaderBlockKey);
    insert(
      "videoLoader",
      {
        blockKey: loaderBlockKey,
        clipRecordingMaxCountdownNumber: this.clipRecordingMaxCountdownNumber,
      },
      undefined,
      videoLoaderInsertIndex,
      true
    );
    const previousBlockIndex = getCurrentBlockIndex() - 1;
    if (isPreviousBlockEmpty(previousBlockIndex)) {
      try {
        this.props.editorInstance.blocks.delete(previousBlockIndex);
      } catch (e) {
        // when focus is lost,
        // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
        // will happen.
        // ignore this for now. it's editor js error.
        // https://github.com/codex-team/editor.js/pull/1218
      }
    }
    insert();
    this.props.editorInstance.caret.setToBlock(getCurrentBlockIndex());
    if (this.props.editorInstance.blocks.getBlockByIndex(getCurrentBlockIndex() - 1)) {
      this.props.editorInstance.blocks.getBlockByIndex(getCurrentBlockIndex() - 1).holder.scrollIntoView();
    }
    // });
  }

  clipRecordStart() {
    if (this.props.applicationType !== "extension") {
      Sweetalert.fire({
        target: `.${styles[`video-document-container`]}`,
        heightAuto: false,
        customClass: {
          container: "position-absolute",
        },
        title: this.props.lang === "ko" ? "준비 중 입니다!" : "Coming soon!",
        html:
          this.props.lang === "ko"
            ? `
                현재 ${this.props.applicationType === "web" ? "슬리드 사이트" : "데스크톱용 슬리드"} 내에서의 클립 녹화를 할 수 없습니다. <br/>
                슬리드 확장자를 이용해 해당 사이트에서 이용해주세요.
            `
            : `
                Currently, video snippet is not available in ${this.props.applicationType === "web" ? "Slid website" : "Slid for Desktop"}. <br/>
                Please try again in video websites using Slid extension.
            `,
        icon: "info",
        confirmButtonText: this.props.lang === "ko" ? "확인" : "Okay",
      });
      return;
    }

    if (this.props.applicationType === "extension") {
      trackEvent({
        eventType: "Check VIDEO SNIPPET PERMISSION in video note page",
        eventProperties: {
          is_permission_needed: !this.props.isExtensionMediaPermitted,
        },
      });

      if (!this.props.isExtensionMediaPermitted) {
        this.props.setIsGettingExtensionMediaPermission(true);
        this.props.setIsGettingClipRecordingMediaPermission(true);
        this.props.setShowExtensionMediaPermissionModal(true);
        return;
      }
    }

    let shouldBeDisabled = false;
    urls["website_urls"].forEach((disallowedUrl) => {
      if (this.props.currentVideo.originUrl.includes(disallowedUrl)) shouldBeDisabled = true;
    });
    if (shouldBeDisabled) {
      Sweetalert.fire({
        target: `.${styles[`video-document-container`]}`,
        heightAuto: false,
        customClass: {
          container: "position-absolute",
        },
        title: this.props.lang === "ko" ? "클립 녹화 이용불가!" : "Not available!",
        html:
          this.props.lang === "ko"
            ? `
                해당 사이트 내에서는 클립 녹화를 할 수 없습니다.
            `
            : `
                Video Snippet is not available in this website.
            `,
        icon: "warning",
        confirmButtonText: this.props.lang === "ko" ? "닫기" : "Okay",
      });
      return;
    }
    if (this.props.isRecordActive) return;
    this.props.setIsRecordActive(true);
    this.countdownId = setInterval(() => {
      this.startCountdown();
    }, 1000);
    this.insertVideoLoader();
    recordClip({
      applicationType: this.props.applicationType,
    });
    sendAmplitudeData(`SLID_2_START_CLIP_RECORDING`);
    trackEvent({
      eventType: eventTypes.success.CLIP_RECORDING_START,
    });
  }

  async clipRecordStop() {
    this.props.setIsRecordActive(false);
    clearInterval(this.countdownId);
    this.props.setClipRecordingCountdownNumber(this.clipRecordingMaxCountdownNumber);
    // Replace videoLoad with loader when loading video
    const savedData = await this.props.editorInstance.save();
    const loaderBlockKey = this.loaderBlockKeys.shift();
    savedData.blocks.forEach((block, index) => {
      if (block.type !== "videoLoader") return;
      if (block.data.blockKey === loaderBlockKey) {
        this.props.editorInstance.blocks.delete(index);
        this.props.editorInstance.blocks.insert(
          "loader",
          {
            blockKey: loaderBlockKey,
          },
          undefined,
          index,
          true
        );
      }
    });
    // push loaderBlockKey again to let video block to know where to insert.
    this.loaderBlockKeys.push(loaderBlockKey);
    stopRecordClip({
      applicationType: this.props.applicationType,
    });
    trackEvent({
      eventType: "Click clip recording end",
    });
  }

  showSelectAreaPopup() {
    if (this.props.applicationType) {
      trackEvent({
        eventType: "Click snip capture",
        eventProperties: {
          depth: this.props.applicationType === "desktop" ? (this.state.isDesktopCaptureAreaSet ? 2 : 1) : undefined,
        },
      });
    }
    if (!this.props.confirmPrivilege(SlidFeatures.snipCapture)) return this.props.showInsufficientPrivilegeModal();
    if (this.props.applicationType === "extension") {
      // snip Capture request
      sendSetSnipCaptureAreaRequestToParentWindow(true);
    } else if (this.props.applicationType === "desktop") {
      // for v2.0.3+
      if (findLatestOfTwoVersions(this.props.desktopVersion, "2.0.3") === this.props.desktopVersion) {
        const didSendCaptureMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_DRAG_AND_DROP_CAPTURE", payload: null });
        if (!didSendCaptureMessageToPrimary) {
          this.showDesktopGuideToRestart();
          return;
        }
      }
      // for v1.3.0+
      else if (findLatestOfTwoVersions(this.props.desktopVersion, "1.3.0") === this.props.desktopVersion) {
        const didSendCaptureMessageToPrimary = this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_SHOW_CAPTURE_AREA", payload: null });
        if (didSendCaptureMessageToPrimary) {
          this.props.setIsCaptureAreaActive(true);
        } else {
          this.showDesktopGuideToRestart();
          return;
        }
      } else {
        // for versions ealier than v1.3.0
        if (!this.props.selectedSourceData) {
          this.showGuideMessageForDesktopCaptureFailure();
          return;
        }
        const didSendCaptureMessageToPrimary = this.sendMessageToPrimary({
          type: "IFRAME_TO_PRIMARY_SHOW_CAPTURE_AREA",
          payload: {
            selectedWindowId: this.props.selectedSourceData.id,
            selectedWindowName: this.props.selectedSourceData.name,
            selectedWindowRect: this.props.desktopDefaultCaptureRect,
            captureRect: this.props.desktopCaptureRect,
          },
        });
        if (didSendCaptureMessageToPrimary) {
          this.props.setIsCaptureAreaActive(true);
        } else {
          this.showGuideMessageForDesktopCaptureFailure();
          return;
        }
      }
    } else if (this.props.applicationType === "web") {
      if (!this.props.isExtensionInstalled) {
        return showDownloadExtensionModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
        });
      }
      if (this.state.isWebCaptureAvailable) {
        // Start snip capture
        this.props.setIsCaptureAreaSetForSnipCapture(true);
      } else {
        showWantToContinueCapturingModal({
          showModal: this.props.showModal,
          closeModal: this.props.closeModal,
          history: this.props.history,
        });
      }
    }
  }

  resetCaptureArea() {
    sendResetCaptureAreaForOneClickCaptureRequestToParentWindow();
  }

  async onDocumentFolderChange(selectedFolderDocumentKey) {
    let currentDocument = this.props.currentDocument;
    if (!currentDocument) {
      currentDocument = await this.props.createDocument({ origin: "vdocs" });
      if (currentDocument.error_message) return;
      this.props.history.replace(`./${currentDocument["document_key"]}`);
    }
    await this.props.moveDocument({
      parentKey: selectedFolderDocumentKey,
      documentKeys: [currentDocument["document_key"]],
      location: "Video note page",
      from: "single selection",
      type: "select",
    });
    this.props.fetchDocument({
      documentKey: currentDocument["document_key"],
    });
    this.props.fetchDocuments();
  }

  openNewFolderPopup() {
    Sweetalert.fire({
      target: !this.props.isMobile && `.${styles[`video-document-container`]}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: this.props.lang === "ko" ? "새 폴더 생성" : "Create new folder",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: this.props.lang === "ko" ? "폴더 이름을 입력하세요." : "Enter title",
      },
      showCancelButton: true,
      confirmButtonText: this.props.lang === "ko" ? "생성" : "Create",
      cancelButtonText: this.props.lang === "ko" ? "취소" : "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Sweetalert.fire({
          title: this.props.lang === "ko" ? "생성 중..." : "Creating...",
          html: this.props.lang === "ko" ? "새 폴더를 생성 중입니다..." : `Creating new folder...`,
          target: `.${styles[`video-document-container`]}`,
          heightAuto: false,
          customClass: {
            container: "position-absolute",
          },
          didOpen: () => {
            Sweetalert.showLoading();
          },
        });
        const createdFolder = await this.props.createFolder({
          title: result.value,
          parentKey: "root",
          origin: "vdocs",
        });
        if (createdFolder.error_message) return;
        // move current document to created folder
        let currentDocument = this.props.currentDocument;
        if (!currentDocument) {
          currentDocument = await this.props.createDocument({ origin: "vdocs" });
          if (currentDocument.error_message) return;
          this.props.history.replace(`./${currentDocument["document_key"]}`);
        }
        await this.props.moveDocument({
          parentKey: createdFolder["document_key"],
          documentKeys: [currentDocument["document_key"]],
          location: "Video note page",
          from: "single selection",
        });
        this.props.fetchDocument({
          documentKey: currentDocument["document_key"],
        });
        this.props.history.push(`/vdocs/${currentDocument["document_key"]}`);
        this.props.fetchDocuments();
        Sweetalert.close();
      }
    });
  }

  async saveDocument() {
    if (this.props.isReadOnly) return;
    const updatedContents = await this.props.editorInstance.save();

    // updating an existing document
    if (this.props.currentDocument && this.props.currentDocument.document_key !== "new") {
      const updatedDocumentResponse = await this.props.updateDocument({
        documentKey: this.props.currentDocument["document_key"],
        title: this.props.currentDocument["title"],
        content: updatedContents,
        origin: "vdocs",
      });
      if (updatedDocumentResponse.error_message) return;
      // filter out if video is already registered
      if (this.props.applicationType === "extension") {
        if (this.props.currentVideo) {
          let isAlreadyRegistered = false;
          this.props.currentDocument["mapped_videos"] &&
            this.props.currentDocument["mapped_videos"].forEach((mappedVideo) => {
              if (mappedVideo["video_origin_url"] === this.props.currentVideo.videoUrl && mappedVideo["website_origin_url"] === this.props.currentVideo.originUrl) {
                isAlreadyRegistered = true;
              }
            });
          if (isAlreadyRegistered) return;
          const videoRegisterInfo = await this.props.registerVideo({
            videoInfo: this.props.currentVideo,
            documentKey: this.props.currentDocument["document_key"],
          });

          if (!videoRegisterInfo || videoRegisterInfo.error_message || !videoRegisterInfo["video_key"]) {
            Sentry.withScope((scope) => {
              scope.setLevel("error");
              Sentry.captureMessage("When saving existing document in extension, attempt to register video was made but failed.");
            });
            return;
          }
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
          const currentVideoKey = videoRegisterInfo["video_key"];
          await this.props.updateCurrentVideo({
            videoKey: currentVideoKey,
          });
        } else if (!this.props.currentVideo) {
          Sentry.withScope((scope) => {
            scope.setLevel("error");
            Sentry.captureMessage("Current video is not set in redux, when saving existing document in extension, no attempt to register video was made.");
          });
        }
      }
    } else {
      // creating a new document
      const createdDocumentResponse = await this.props.createDocument({
        content: updatedContents,
        origin: "vdocs",
      });
      if (createdDocumentResponse.error_message) return;
      this.props.history.replace(`./${this.props.currentDocument["document_key"]}`);

      if (this.props.applicationType === "extension") {
        if (this.props.currentVideo) {
          const videoRegisterInfo = await this.props.registerVideo({
            videoInfo: this.props.currentVideo,
            documentKey: this.props.currentDocument["document_key"],
          });

          if (!videoRegisterInfo || videoRegisterInfo.error_message || !videoRegisterInfo["video_key"]) {
            Sentry.withScope((scope) => {
              scope.setLevel("error");
              Sentry.captureMessage("When saving a new document in extension, attempt to register video was made but failed.");
            });
            return;
          }
          sendAmplitudeData(`SLID_1_REGISTER_VIDEO`);
          const currentVideoKey = videoRegisterInfo["video_key"];
          await this.props.updateCurrentVideo({
            videoKey: currentVideoKey,
          });
        }
      } else if (!this.props.currentVideo) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Current video is not set in redux, when saving a new document in extension, no attempt to register video was made. ");
        });
      }
    }
  }

  openDocumentHistory() {
    if (!this.props.currentDocument) return;
    const currentDocumentKey = this.props.currentDocument["document_key"];
    if (!currentDocumentKey) return;
    if (this.props.applicationType === "extension") {
      window.open(`/history/${currentDocumentKey}`);
    } else if (this.props.applicationType === "desktop") {
      this.props.history.push(`/history/${currentDocumentKey}`);
    } else if (this.props.applicationType === "web") {
      this.props.history.push(`/history/${currentDocumentKey}`);
    }
  }

  renderCaptureSettingButton() {
    if (this.props.applicationType === "desktop") {
      if (!(findLatestOfTwoVersions(this.props.desktopVersion, "2.0.6") === this.props.desktopVersion)) return;
      if (!this.state.isDesktopCaptureAreaSet) return;
      return (
        <div
          className={`${styles[`setting-button`]}`}
          onClick={(e) => {
            e.stopPropagation();
            trackEvent({
              eventType: "Click capture setting button",
            });
            if (findLatestOfTwoVersions(this.props.desktopVersion, "2.5.0") === this.props.desktopVersion) {
              this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_START_SETTING_CAPTURE_AREA", payload: null });
            } else {
              this.sendMessageToPrimary({ type: "IFRAME_TO_PRIMARY_TOGGLE_SELECT_CAPTURE_AREA", payload: null });
            }
          }}
        >
          <Tooltip title={this.props.t("ChangeCaptureArea", { ns: "VideoCaptureButton" })} placement={"top"}>
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </Tooltip>
        </div>
      );
    } else if (this.props.applicationType === "extension") {
      return (
        <div
          className={`${styles[`setting-button`]}`}
          onClick={(e) => {
            e.stopPropagation();
            trackEvent({
              eventType: "Click capture setting button",
            });
            this.resetCaptureArea();
          }}
        >
          <Tooltip title={this.props.t("SetCaptureArea", { ns: "VideoCaptureButton" })} placement={"top"}>
            <div>
              <Icon icon={`chevronDown20`} color={`--gray17`} />
            </div>
          </Tooltip>
        </div>
      );
    } else {
      if (!this.state.isWebCaptureAvailable) return;
      return (
        <Tooltip title={this.props.t("SetCaptureArea", { ns: "VideoCaptureButton" })}>
          <div
            className={`${styles[`setting-button`]}`}
            onClick={(e) => {
              e.stopPropagation();
              trackEvent({
                eventType: "Click capture setting button",
              });
              this.props.setIsCaptureAreaSetForOneClickCapture(true);
            }}
          >
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </div>
        </Tooltip>
      );
    }
  }

  getOneClickCaptureButtonClassName() {
    switch (this.props.applicationType) {
      case "web":
        if (this.props.isExtensionInstalled && this.state.isWebCaptureAvailable) {
          return `${styles[`capture-btn`]} btn btn-primary`;
        } else {
          return `${styles[`capture-btn-inactive`]} btn btn-light`;
        }
      case "desktop":
        if (findLatestOfTwoVersions(this.props.desktopVersion, "2.0.0") === this.props.desktopVersion) {
          if (this.state.isDesktopCaptureAreaSet) return `${styles[`capture-btn`]} btn btn-primary`;
          else return `${styles[`capture-btn-inactive`]} btn btn-light`;
        }
        break;
      case "extension":
        return `${styles[`capture-btn`]} btn btn-primary`;
      default:
        return `${styles[`capture-btn`]} btn btn-primary`;
    }
  }

  setCanvasForOneClickCapture() {
    const addSetDefaultButton = () => {
      const setDefaultBtn = document.createElement("button");
      setDefaultBtn.className = "set-default-btn";
      setDefaultBtn.type = "button";
      setDefaultBtn.innerText = this.props.t("ApplyChanges", { ns: "VideoCaptureButton" });
      setDefaultBtn.onclick = () => {
        setDefaultBtn.remove();
        setOneClickCaptureRect();
        this.props.setIsCaptureAreaSetForOneClickCapture(false);
        this.props.setIsCaptureAreaReset(false);
        document.querySelectorAll(".remove-selected-area-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(setDefaultBtn);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = setDefaultBtn.getBoundingClientRect().width;
      setDefaultBtn.style.left = `${rect.left + rect.width + this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left - buttonWidth}px`;
      setDefaultBtn.style.top = `${rect.top + rect.height + this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top + 10}px`;
    };
    const setOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
      this.props.setCaptureRect(oneClickCaptureRect);
    };

    const addRemoveSelectedAreaButton = () => {
      const removeSelectedAreaButton = document.createElement("button");
      removeSelectedAreaButton.className = "remove-selected-area-btn";
      removeSelectedAreaButton.type = "button";
      removeSelectedAreaButton.innerText = this.props.t("ResetCaptureArea", { ns: "VideoCaptureButton" });
      removeSelectedAreaButton.onclick = () => {
        removeSelectedAreaButton.remove();
        resetOneClickCaptureRect();
        this.props.setIsCaptureAreaSetForOneClickCapture(false);
        this.props.setIsCaptureAreaReset(false);
        document.querySelectorAll(".set-default-btn").forEach((element) => {
          element.remove();
        });
      };
      const canvasWrapper = document.querySelector("#canvas-wrapper");
      if (canvasWrapper) {
        canvasWrapper.appendChild(removeSelectedAreaButton);
      }
      // Set button position after it is rendered since we can only know the with after it is rendered.
      const buttonWidth = removeSelectedAreaButton.getBoundingClientRect().width;
      const setDefaultButtonWidth = document.querySelector(".set-default-btn")?.getBoundingClientRect()?.width;
      removeSelectedAreaButton.style.left = `${rect.left + rect.width + this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left - setDefaultButtonWidth - buttonWidth - 10}px`;
      removeSelectedAreaButton.style.top = `${rect.top + rect.height + this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top + 10}px`;
    };

    const resetOneClickCaptureRect = () => {
      const oneClickCaptureRect = {
        left: 0,
        top: 0,
        width: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().width - 3,
        height: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().height - 3,
      };
      this.props.setCaptureRect(oneClickCaptureRect);
    };

    const handleMouseUpForOneClickCapture = () => {
      addSetDefaultButton();
      addRemoveSelectedAreaButton();
      canvas.__eventListeners["mouse:move"] = [];
      canvas.__eventListeners["mouse:up"] = [];
      shouldRedrawArea = false;
    };
    canvas = new fabric.Canvas("slid-video-one-click-capture-area", {
      width: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().width,
      height: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: this.props.captureRect ? this.props.captureRect.left : 0,
      top: this.props.captureRect ? this.props.captureRect.top : 0,
      width: this.props.captureRect ? this.props.captureRect.width : this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().width - 3,
      height: this.props.captureRect ? this.props.captureRect.height : this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().height - 3,
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    addSetDefaultButton();
    addRemoveSelectedAreaButton();
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left, options.e.clientY - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left, options.e.clientY - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", this.handleMouseMove.bind(this));
      canvas.on("mouse:up", handleMouseUpForOneClickCapture);
    });
  }

  handleMouseMove(options) {
    const generateSelectedArea = () => {
      rect = new fabric.Rect({
        left: mouseX,
        top: mouseY,
        width: 0,
        height: 0,
        id: "selected-area",
        stroke: "#007bff",
        strokeWidth: 3,
        lineWidth: 3,
        strokeDashArray: [6, 3],
        fill: "rgba(255,255,255,0.3)",
        selectable: false,
        defaultCursor: "crosshair",
      });
      canvas.add(rect);
    };
    if (mouseDownEventsToIgnore > 0) {
      mouseDownEventsToIgnore--;
      return;
    }
    if (!shouldRedrawArea) {
      canvas.getObjects().forEach((object) => {
        if (object.id === "selected-area") {
          canvas.remove(object);
        }
      });
      document.querySelectorAll(".set-default-btn").forEach((btn) => btn.remove());
      document.querySelectorAll(".remove-selected-area-btn").forEach((btn) => btn.remove());
      // generate a new div on the fly
      generateSelectedArea();
      shouldRedrawArea = true;
      return;
    }
    const [newMouseX, newMouseY] = [
      options.e.clientX - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left,
      options.e.clientY - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top,
    ];
    [mouseX, mouseY] = [newMouseX, newMouseY];
    // check if we can resize
    if (mouseX <= 0) {
      return;
    } else if (mouseX > canvas.width) {
      return;
    }
    if (mouseY <= 0) {
      return;
    } else if (mouseY > canvas.height) {
      return;
    }
    let filteredWidth = Math.abs(mouseX - initX);
    let filteredHeight = Math.abs(mouseY - initY);
    let filteredLeft = rect.left;
    let filteredTop = rect.top;
    if (mouseX > initX) {
      filteredLeft = initX;
    } else {
      filteredLeft = mouseX;
    }
    if (mouseY < initY) {
      filteredTop = mouseY;
    } else {
      filteredTop = initY;
    }
    rect.set({
      width: filteredWidth,
      height: filteredHeight,
      left: filteredLeft,
      top: filteredTop,
    });
    canvas.renderAll();
  }

  async setCanvasForSnipCapture() {
    const handleMouseUpForSnipCapture = () => {
      this.props.setIsCaptureAreaSetForSnipCapture(false);
      const snipCaptureRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
      };
      setTimeout(() => {
        // Delay snip capture while removing select area div
        if (this.externalPort) {
          try {
            this.externalPort.postMessage({
              action: "WEB_TO_BACK_captureCurrentArea",
              data: {
                captureRect: snipCaptureRect,
                videoContainerRect: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect(),
                devicePixelRatio: window.devicePixelRatio,
              },
            });
          } catch (e) {
            // If web - extension background connection is lost, reload to connect again.
            window.location.reload();
          }
        }
        canvas.off("mouse:move", this.handleMouseMove.bind(this));
        shouldRedrawArea = false;
      }, 100);
    };
    canvas = new fabric.Canvas("slid-video-snip-capture-area", {
      width: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().width,
      height: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().height,
      defaultCursor: "crosshair",
      id: "snip-capture-canvas",
    });
    shouldRedrawArea = false;
    canvas.selection = false;
    rect = new fabric.Rect({
      left: 0,
      top: 0,
      width: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().width - 3,
      height: this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().height - 3,
      id: "selected-area",
      stroke: "#007bff",
      strokeWidth: 3,
      lineWidth: 3,
      strokeDashArray: [6, 3],
      fill: "rgba(255,255,255,0.3)",
      selectable: false,
      hoverCursor: "crosshair",
    });
    canvas.add(rect);
    canvas.on("mouse:down", (options) => {
      // save the initial mouse position
      [initX, initY] = [options.e.clientX - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left, options.e.clientY - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top];
      // save mouse position inside the window
      [mouseX, mouseY] = [options.e.clientX - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().left, options.e.clientY - this.props.videoPlayerRef?.wrapper?.getBoundingClientRect().top];
      mouseDownEventsToIgnore = 5;
      canvas.on("mouse:move", this.handleMouseMove.bind(this));
      canvas.on("mouse:up", handleMouseUpForSnipCapture);
    });
  }

  renderDesktopCaptureAreaSettingModal = () => {
    if (this.props.applicationType === "desktop" && findLatestOfTwoVersions(this.props.desktopVersion, "2.5.0") === this.props.desktopVersion && this.state.isDesktopCaptureAreaSetting)
      return (
        <>
          <Dim type="gray4" closableDim={false} />
          <CaptureAreaSettingContainer>
            <img src="/src/icons/icon_gray_slid_36.svg" alt="" />
            <Typography13 weight={400} color="--gray9" text={this.props.t("SettingCaptureAreaMessage", { ns: "Desktop" })} textAlign="center" marginTop="8px" />
            <SelectOtherAppButton
              onClick={() => {
                this.openShowSelectWindowModal();
                this.setState({ isDesktopCaptureAreaSetting: false });
              }}
            >
              <Typography13 weight={700} color="--gray9" text={this.props.t("SelectAnotherAppButton", { ns: "Desktop" })} />
            </SelectOtherAppButton>
          </CaptureAreaSettingContainer>
        </>
      );
  };

  renderCaptureBar = (width) => {
    if (isTabletApp() && !this.props.tabletDefaultCropArea && this.state.shouldShowTabletCaptureGuideButton) {
      const setShouldShowTabletCaptureGuideTooltipOnTouch = (value) => {
        this.setState({
          shouldShowTabletCaptureGuideTooltip: value,
        });
      };
      return (
        <div className={`${styles[`capture-button-positioner`]}`}>
          {this.state.shouldShowTabletCaptureGuideTooltip && <TabletStartCapturingButtonToolTip setShouldShowTabletCaptureGuideTooltipOnTouch={setShouldShowTabletCaptureGuideTooltipOnTouch} />}
          <TabletStartCapturingButton
            size={`large`}
            appearance={`primary`}
            color={`--gray1`}
            text={this.props.t(`TabletStartCapturingButtonText`, { ns: "VideoNote" })}
            callback={() => {
              this.props.setIsListOpen(false);
              if (!this.state.shouldShowTabletCaptureGuideTooltip) {
                trackEvent({
                  eventType: "Click HOW TO TAKE SCREENSHOT BUTTON in video note page",
                });
              } else {
                trackEvent({
                  eventType: "Close TAKING SCREENSHOT in video note page",
                });
              }

              this.setState({
                shouldShowTabletCaptureGuideTooltip: !this.state.shouldShowTabletCaptureGuideTooltip,
              });
            }}
            style={{ backgroundColor: this.state.shouldShowTabletCaptureGuideTooltip ? "var(--purple9)" : "var(--purple7)" }}
            icon={<Icon icon={`1Click28`} color={`--gray1`} />}
          />
        </div>
      );
    }
    const renderSetCaptureWindowButton = () => {
      if (this.props.applicationType === "desktop" && findLatestOfTwoVersions(this.props.desktopVersion, "2.5.0") === this.props.desktopVersion) {
        return (
          <div className={`${styles[`capture-button-positioner`]}`}>
            {this.state.showDesktopCaptureTooltip && <DesktopCaptureGuideTooltip />}
            <div
              className={`${styles[`capture-button`]}`}
              slid-cy={`one-click-capture-btn`}
              disabled={this.state.isCapturing}
              onClick={() => this.openShowSelectWindowModal()}
              onContextMenu={(event) => {
                event.preventDefault();
              }}
            >
              <Tooltip title={this.props.lang === "ko" ? "캡처 앱 변경" : "Set capture app"} placement={"top"}>
                <div className={styles[`capture-button-overlay-area`]}></div>
              </Tooltip>

              <img src="/src/icons/icon_select_app.svg" alt="" />
              <span className={`${styles[`button-text`]}`}>{this.props.t("SetCaptureWindow", { ns: "VideoNote" })}</span>
            </div>
          </div>
        );
      }
    };
    const defaultCaptureBar = (
      <div className={`${styles[`capture-button-container`]}`}>
        <Tooltip placement={`top`} title={this.props.t("SnipCapture", { ns: "VideoCaptureButton" })} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
          <div
            className={`${styles[`capture-button`]}`}
            onClick={() => {
              this.showSelectAreaPopup();
            }}
          >
            <Icon icon={`snip28`} color={`--gray17`} />
            <span className={`${styles[`button-text`]}`}>{this.props.t("Snip", { ns: "VideoNote" })}</span>
          </div>
        </Tooltip>
        <div className={`${styles[`capture-button-positioner`]}`}>
          <div
            className={`${styles[`capture-button`]}`}
            slid-cy={`one-click-capture-btn`}
            disabled={this.state.isCapturing}
            onClick={() => {
              if (this.props.applicationType === "desktop" && this.state.showDesktopCaptureTooltip)
                this.setState({
                  showDesktopCaptureTooltip: false,
                });
              this.manualCapture();
            }}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
            onMouseUp={(event) => {
              event.preventDefault();
            }}
            onContextMenu={(event) => {
              event.preventDefault();
            }}
          >
            <Tooltip
              title={
                this.props.applicationType === "desktop" && findLatestOfTwoVersions(this.props.desktopVersion, "2.0.0") === this.props.desktopVersion
                  ? this.state.isDesktopCaptureAreaSet
                    ? // when button is blue
                      this.props.t("1ClickCapture", { ns: "VideoCaptureButton" })
                    : // when button is grey
                      this.props.t("SetCaptureArea", { ns: "VideoCaptureButton" })
                  : // etc
                    this.props.t("1ClickCapture", { ns: "VideoCaptureButton" })
              }
              description={
                this.props.applicationType === "desktop" && findLatestOfTwoVersions(this.props.desktopVersion, "2.0.0") === this.props.desktopVersion
                  ? this.state.isDesktopCaptureAreaSet
                    ? // when button is blue
                      isMacOs
                      ? "(Cmd + /)"
                      : "(Alt + /)"
                    : // when button is grey
                      ""
                  : // etc
                  isMacOs
                  ? "(Cmd + /)"
                  : "(Alt + /)"
              }
              placement={"top"}
            >
              <div className={styles[`capture-button-overlay-area`]}></div>
            </Tooltip>
            {this.renderCaptureSettingButton()}
            <Icon icon={`1Click28`} color={`--gray17`} />
            <span className={`${styles[`button-text`]}`}>{this.props.t("1Click", { ns: "VideoNote" })}</span>
          </div>
        </div>
        {this.props.applicationType === "desktop" && this.props.isSTTSupported && <DesktopSmartLiveTextVideoNoteButton />}
        {renderSetCaptureWindowButton()}
        {(this.props.applicationType === "extension" || this.props.applicationType === "web") && (
          <button
            className={`${styles[`capture-button`]}`}
            onClick={() => {
              trackEvent({ eventType: eventTypes.click.CLIP_RECORDING_IN_VIDEO_NOTE_PAGE });
              if (!this.props.confirmPrivilege(SlidFeatures.clipRecording)) return this.props.showInsufficientPrivilegeModal();
              if (this.props.isSTTToggledOn || this.props.isAutoNotesToggledOn) {
                if (this.props.isSTTToggledOn) {
                  this.props.setMediaRecordingChangeDirection("SLT_TO_SNIPPET");
                }
                if (this.props.isAutoNotesToggledOn) {
                  this.props.setMediaRecordingChangeDirection("AUTO_NOTES_TO_SNIPPET");
                }
                this.props.setShowDisableMediaRecordingModal(true);
                return;
              }
              if (this.props.isRecordActive) {
                this.clipRecordStop();
              } else {
                this.clipRecordStart();
              }
            }}
          >
            {!this.props.isRecordActive ? <Icon icon={`recording28`} color={`--gray17`} /> : <Icon icon={`usingRecording28`} color={`--red7`} />}
            <span className={styles[`button-text`]}>{!this.props.isRecordActive ? this.props.t("Record", { ns: "VideoNote" }) : this.props.t("Recording", { ns: "VideoNote" })}</span>
          </button>
        )}

        {this.props.applicationType === "extension" && this.props.isAutoNotesSupported && this.props.currentVideo?.videoType === "youtube" && <AutoNotesVideoNoteButton />}
        {this.props.applicationType === "extension" && <SmartLiveTextVideoNoteButton />}
      </div>
    );
    const tabletEditCropAreaButton = (
      <EditCropAreaButton
        onClick={() => {
          trackEvent({
            eventType: "Click RESET CAPTURE AREA in video note page",
          });
          this.props.setEditImageCropArea(true);
          this.props.setImageCroppingModalImageSource(null);
          this.props.setIsImageCroppingModalOpen(true);
          this.props.setShouldUpdateImageByCrop(false);
          this.props.setCroppingImageBlockData(null);
        }}
      >
        <img src="/src/icons/icon_edit_crop_area.svg" alt="edit crop area" width={24} />
        <Typography13 weight={700} color="--gray15" text={this.props.t("ResetTabletCaptureArea", { ns: "VideoNote" })} />
      </EditCropAreaButton>
    );
    // For web / extension -> Just show capture bar
    if (isTabletApp()) {
      if (this.props.tabletDefaultCropArea) return tabletEditCropAreaButton;
      else return;
    }
    if (this.props.applicationType !== "desktop") return defaultCaptureBar;
    // For desktop
    // version >= 2.5.0 should show capture bar only after capture area set
    if (this.props.applicationType === "desktop" && findLatestOfTwoVersions("2.5.0", this.props.desktopVersion) === this.props.desktopVersion) {
      if (this.state.isDesktopCaptureAreaSet) return defaultCaptureBar;
      else return <></>;
    }
    // version < 2.5.0 just show capture bar
    if (this.props.applicationType === "desktop" && findLatestOfTwoVersions("2.5.0", this.props.desktopVersion) === "2.5.0") {
      return defaultCaptureBar;
    }
  };

  setShouldShowTabletCaptureGuideButton = (value) => {
    this.setState({
      shouldShowTabletCaptureGuideButton: value,
    });
  };
  render() {
    return (
      <ReactResizeDetector handleWidth handleHeight>
        {({ width }) => {
          return (
            <div className={styles[`video-document-container`]} id={`video-document-container`}>
              {this.renderDesktopCaptureAreaSettingModal()}
              <EditorNoteLoadingScreen />
              {this.state.isFolderSelectorActive && <div className={`${styles[`folder-dropdown-wrapper`]}`} onClick={() => this.setState({ isFolderSelectorActive: false })}></div>}
              <div className={`${styles[`list-container`]} ${this.props.isListOpen ? "" : "d-none"}`} ref={this.listContainerRef}>
                <DocumentListView />
              </div>
              <div className={`${styles[`header`]} ${this.props.screenOrientation === "vertical" ? styles[`vertical`] : styles[`horizontal`]}`}>
                <VideoNoteHeader
                  currentCumFolderName={this.state.currentCumFolderName}
                  isFolderSelectorActive={this.state.isFolderSelectorActive}
                  setState={this.setState.bind(this)}
                  isMobile={this.props.isMobile}
                  openRecentNote={this.state.openRecentNote}
                  onDocumentFolderChange={this.onDocumentFolderChange.bind(this)}
                  openNewFolderPopup={this.openNewFolderPopup.bind(this)}
                  saveDocument={this.saveDocument.bind(this)}
                />
              </div>

              {this.props.userData?.payment === "trial" &&
                this.props.screenOrientation === "horizontal" &&
                (this.props.userStudyChallengeData?.isActive ? <StudyChallengeSavedTimeBanner width={width} /> : <SavedTimeBanner width={width} />)}
              {!isTabletApp() && <VideoNoteStudyChallengeInfo />}

              <EditorContainer
                applicationType={this.props.applicationType}
                id={`editor-container`}
                slid-cy={`vdocs-editor-container`}
                className={`${styles[`editor-container`]} ${this.props.screenOrientation === "vertical" ? styles[`vertical`] : styles[`horizontal`]}`}
                onClick={(event) => {
                  if (this.props.isListOpen && !this.listContainerRef.current.contains(event.target)) {
                    this.props.setIsListOpen(false);
                  }
                }}
              >
                <div className={`${styles[`editor-vertical-scroll-wrapper`]}`}>{this.props.applicationType && <EditorComponent />}</div>
              </EditorContainer>
              {this.props.screenOrientation !== "vertical" && (
                <>
                  {width > 570 && (
                    <div
                      className={styles[`save-button-wrapper`]}
                      onClick={() => {
                        sendAmplitudeData(`Save note manually`, {
                          type: "save button",
                        });

                        this.saveDocument();
                        this.props.fetchDocuments();
                      }}
                    >
                      {width > 600 && (
                        <div className={styles[`save-button-icon-wrapper`]}>
                          <Icon icon={`thickCheck16`} color={this.props.isEditorSaved ? `--gray9` : `--gray7`} />
                        </div>
                      )}
                      {this.props.isEditorSaved ? (
                        <Typography11 text={this.props.t("AutoSaved", { ns: "VideoNote" })} color={`--gray9`} />
                      ) : (
                        <Typography11 text={this.props.t("Saving", { ns: "VideoNote" })} color={`--gray7`} />
                      )}
                    </div>
                  )}
                  {this.renderCaptureBar(width)}
                  {this.props.applicationType === "desktop" && findLatestOfTwoVersions("2.5.0", this.props.desktopVersion) === this.props.desktopVersion && !this.state.isDesktopCaptureAreaSet && (
                    <div className={`${styles[`desktop-capture-bar-container`]}`}>
                      <Tooltip title={this.props.t("StartSnipCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Option + A)" : "(Alt + A)"}>
                        <div>
                          <DesktopSnipCaptureButton
                            appearance="line"
                            size="medium"
                            isDisabled={this.state.isSetCaptureAreaButtonClicked || this.state.isDesktopCaptureAreaSetting}
                            isLoading={!this.props.iframePort}
                            icon={<Icon icon={`snip28`} height={24} width={24} color={"--gray9"} />}
                            text={this.props.t("StartSnipCaptureButton", { ns: "Desktop" })}
                            color={`--gray9`}
                            style={{ borderColor: `var(--gray4)`, backgroundColor: "var(--white)" }}
                            callback={() => {
                              trackEvent({ eventType: "Click START SNIP CAPTURING in video note" });
                              if (!this.props.confirmPrivilege(SlidFeatures.snipCapture)) return this.props.showInsufficientPrivilegeModal();
                              this.showSelectAreaPopup();
                            }}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title={this.props.t("StartCaptureButtonTooltip", { ns: "Desktop" })} placement={`top`} description={isMacOs ? "(Cmd + /)" : "(Alt + /)"}>
                        <div>
                          <SetCaptureAreaButton
                            appearance="primary"
                            size="medium"
                            isDisabled={this.state.isSetCaptureAreaButtonClicked || this.state.isDesktopCaptureAreaSetting}
                            isLoading={!this.props.iframePort}
                            icon={<OneClickIcon height={24} width={24} color={this.state.isSetCaptureAreaButtonClicked || this.state.isDesktopCaptureAreaSetting ? "var(--gray5)" : "var(--white)"} />}
                            text={this.props.t("StartCaptureButton", { ns: "Desktop" })}
                            callback={() => {
                              trackEvent({ eventType: "Click START CAPTURING in video note" });
                              if (!this.props.confirmPrivilege(SlidFeatures.oneClickCapture)) return this.props.showInsufficientPrivilegeModal();
                              this.openShowSelectWindowModal();
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {width > 410 && !(this.props.userData?.payment === UserMembership.break) ? (
                    <div className={styles[`beta-help-button-container`]} onMouseEnter={() => this.onMouseEnterHelpButton("sliddy-button")}>
                      <SliddyIcon color={`white`} width={40} height={40} />
                    </div>
                  ) : (
                    width > 570 && (
                      <div className={styles[`help-button-container`]} onMouseEnter={() => this.onMouseEnterHelpButton("help-button")}>
                        <Icon icon={`help8`} color={`--gray13`} width={8} height={15} />
                      </div>
                    )
                  )}
                </>
              )}
              {this.props.isAutoNotesToggledOn && <AutoNotesMainUI width={width} />}
              {this.props.showSliddyChat && <SlidGPT />}
              {this.props.isHelpMenuOpen && <HelpMenu isSliddyAvailable={!(this.props.userData?.payment === UserMembership.break) && this.props.screenOrientation !== "vertical"} />}
              {this.props.showSearchPopup && (
                <PopupLayer
                  isMobile={this.props.isMobile}
                  onClick={(event) => {
                    if (!this.searchPopupRef.current.contains(event.target)) {
                      this.props.setShowSearchPopup(false);
                    }
                  }}
                >
                  <PopupContainer ref={this.searchPopupRef}>
                    <DocumentSearch />
                  </PopupContainer>
                </PopupLayer>
              )}
              {this.props.showDeletedDocumentsPopup && (
                <PopupLayer
                  isMobile={this.props.isMobile}
                  onClick={(event) => {
                    if (!this.deletedDocumentsPopupRef.current.contains(event.target)) {
                      this.props.setShowDeletedDocumentsPopup(false);
                    }
                  }}
                >
                  <PopupContainer ref={this.deletedDocumentsPopupRef}>
                    <DeletedDocuments />
                  </PopupContainer>
                </PopupLayer>
              )}
              {this.props.showSharePopup ? (
                <PopupLayer
                  isMobile={this.props.isMobile}
                  onClick={(event) => {
                    if (!this.sharePopupRef.current.contains(event.target)) {
                      this.props.setShowSharePopup(false);
                    }
                  }}
                  style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
                >
                  <PopupContainer ref={this.sharePopupRef}>
                    <DocumentShare />
                  </PopupContainer>
                </PopupLayer>
              ) : null}
              {this.props.showDisableMediaRecordingModal && (
                <PopupLayer
                  onClick={(event) => {
                    if (!this.disableMediaRecordingModalRef.current.contains(event.target)) {
                      this.props.setShowDisableMediaRecordingModal(false);
                    }
                  }}
                  style={{ backgroundColor: `rgba(33, 37, 41, 0.5)` }}
                >
                  <PopupContainer ref={this.disableMediaRecordingModalRef}>
                    <DisableMediaRecordingModal clipRecordStart={this.clipRecordStart} clipRecordStop={this.clipRecordStop} />
                  </PopupContainer>
                </PopupLayer>
              )}
              {this.props.isImageCroppingModalOpen && !isTabletApp() && <ImageCroppingModal />}
              {this.props.isImageCroppingModalOpen && isTabletApp() && <TabletImageCroppingModal setShouldShowTabletCaptureGuideButton={this.setShouldShowTabletCaptureGuideButton} />}
            </div>
          );
        }}
      </ReactResizeDetector>
    );
  }
}

// Called when the experiment is displayed to the user.
emitter.addPlayListener((experimentName, variantName) => {
  sendAmplitudeData(`Enter vdocs onboarding`, {
    experiment: experimentName,
    type: variantName,
  });
});
const actions = {
  createDocument,
  updateDocument,
  registerVideo,
  updateCurrentVideo,
  registerClip,
  createFolder,
  moveDocument,
  fetchDocuments,
  fetchDocument,
  setIsCaptureAreaActive,
  setCaptureAreaRect,
  setIsListOpen,
  setIsEditorOptionOpen,
  setIsHelpMenuOpen,
  setShowSearchPopup,
  setShowSharePopup,
  setShowDeletedDocumentsPopup,
  setEditorWrapperClassName,

  //clip recording
  setIsRecordActive,
  setClipRecordingCountdownNumber,
  setIsGettingClipRecordingMediaPermission,

  setIsCaptureAreaSetForSnipCapture,
  setCaptureRect,
  setExtensionVersion,
  setIsCaptureAreaSetForOneClickCapture,
  setIsCaptureAreaReset,
  setShowDisableMediaRecordingModal,
  setMediaRecordingChangeDirection,
  setIsExtensionMediaPermitted,
  setShowExtensionMediaPermissionModal,
  setIsGettingExtensionMediaPermission,

  //stt
  setIsSTTToggledOn,
  setShowSmartLiveTextView,
  setStopDesktopSTT,
  setIsGettingSTTMediaPermission,

  //auto notes
  setIsGettingAutoNotesMediaPermission,

  //tablet
  setIsImageCroppingModalOpen,
  setImageCroppingModalImageSource,
  setImageCropArea,
  setEditImageCropArea,
  setShouldUpdateImageByCrop,
  setCroppingImageBlockData,
  setTabletDefaultCropArea,

  // desktop
  setIframePort,
  setDesktopDefaultCaptureRect,
  setDesktopCaptureRect,
  setSelectedSourceData,
  setSelectableProcesses,
};
const mapStateToProps = (state) => ({
  userData: state.slidGlobal.userData,
  userStudyChallengeData: state.slidGlobal.userStudyChallengeData,
  lang: state.slidGlobal.lang,
  applicationType: state.slidGlobal.applicationType,
  extensionVersion: state.slidGlobal.extensionVersion,
  screenOrientation: state.slidGlobal.screenOrientation,
  isExtensionInstalled: state.slidGlobal.isExtensionInstalled,
  currentDocument: state.vdocs.currentDocument,
  currentVideo: state.vdocs.currentVideo,
  documents: state.vdocs.documents,
  captureAreaRect: state.vdocs.captureAreaRect,
  isListOpen: state.vdocs.isListOpen,
  isEditorOptionOpen: state.vdocs.isEditorOptionOpen,
  editorInstance: state.vdocs.editorInstance,
  isReadOnly: state.vdocs.isReadOnly,
  isEditorSaved: state.vdocs.isEditorSaved,
  showSearchPopup: state.vdocs.showSearchPopup,
  showSharePopup: state.vdocs.showSharePopup,
  showDeletedDocumentsPopup: state.vdocs.showDeletedDocumentsPopup,
  editorWrapperClassName: state.vdocs.editorWrapperClassName,
  showPlaceholder: state.vdocs.showPlaceholder,
  isCaptureAreaActive: state.vdocs.isCaptureAreaActive,
  isSmartLiveTextToggledOn: state.sttReducer.isSmartLiveTextToggledOn,
  isGettingSTTMediaPermission: state.sttReducer.isGettingSTTMediaPermission,
  isRecordActive: state.vdocs.isRecordActive,
  clipRecordingCountdownNumber: state.vdocs.clipRecordingCountdownNumber,
  videoPlayerRef: state.vdocs.videoPlayerRef,
  isGettingClipRecordingMediaPermission: state.vdocs.isGettingClipRecordingMediaPermission,
  isCaptureAreaSetForSnipCapture: state.vdocs.isCaptureAreaSetForSnipCapture,
  captureRect: state.vdocs.captureRect,
  isCaptureAreaSetForOneClickCapture: state.vdocs.isCaptureAreaSetForOneClickCapture,
  isCaptureAreaReset: state.vdocs.isCaptureAreaReset,
  isHelpMenuOpen: state.vdocs.isHelpMenuOpen,
  showDisableMediaRecordingModal: state.vdocs.showDisableMediaRecordingModal,
  showExtensionMediaPermissionModal: state.vdocs.showExtensionMediaPermissionModal,
  isExtensionMediaPermitted: state.vdocs.isExtensionMediaPermitted,
  isGettingExtensionMediaPermission: state.vdocs.isGettingExtensionMediaPermission,
  editorLastActiveBlockPosition: state.vdocs.editorLastActiveBlockPosition,
  isSTTToggledOn: state.sttReducer.isSTTToggledOn,
  isSTTActive: state.sttReducer.isSTTActive,
  isSTTSupported: state.vdocs.isSTTSupported,

  //for ai sliddy
  showSliddyChat: state.aiSliddy.showSliddyChat,

  //for auto notes
  isAutoNotesToggledOn: state.autoNotes.isAutoNotesToggledOn,
  isAutoNotesSupported: state.autoNotes.isAutoNotesSupported,
  isGettingAutoNotesMediaPermission: state.autoNotes.isGettingAutoNotesMediaPermission,

  //tablet
  isImageCroppingModalOpen: state.vdocs.isImageCroppingModalOpen,
  editImageCropArea: state.vdocs.editImageCropArea,
  isTabletAppOpenedInSplitView: state.slidGlobal.isTabletAppOpenedInSplitView,
  tabletDefaultCropArea: state.vdocs.tabletDefaultCropArea,

  // desktop
  desktopVersion: state.slidGlobal.desktopVersion,
  iframePort: state.vdocs.iframePort,
  selectedSourceData: state.vdocs.selectedSourceData,
  desktopDefaultCaptureRect: state.vdocs.desktopDefaultCaptureRect,
  desktopCaptureRect: state.vdocs.desktopCaptureRect,
  showGuestModeAlert: state.vdocs.showGuestModeAlert,
  modalOn: state.modal.modalOn,
});

export default withTranslation()(connect(mapStateToProps, actions)(withRouter(withCookies(withHooks(withChannelTalk(VideoDocumentEditor))))));

const EditorContainer = styled.div`
  ${(props) =>
    props.applicationType !== "desktop" &&
    css`
      @media screen and (max-width: 799px) {
        padding-top: 42px;
      }
    `}
`;

const DesktopSnipCaptureButton = styled(Button)`
  && {
    display: flex;
    z-index: 1;
    filter: drop-shadow(var(--boxShadow1));

    :hover {
      background-color: var(--gray4) !important; // Without important, inline style (white background) is prioritized.
      -webkit-transition: background-color 0.2s ease-in-out;
      transition: background-color 0.2s ease-in-out;
    }
  }
`;

const SetCaptureAreaButton = styled(Button)`
  display: flex;
  z-index: 1;
  filter: drop-shadow(var(--boxShadow1));
`;

const CaptureAreaSettingContainer = styled.div`
  z-index: 99999;
  position: fixed;
  top: calc((100vh - 202px) / 2);
  left: calc((100vw - 284px) / 2);
  width: 284px;
  height: 202px;
  background-color: white;
  border-radius: 8px;
  padding: 40px 52px 32px 52px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectOtherAppButton = styled.div`
  width: auto;
  height: 34px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--gray5);
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditCropAreaButton = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 20px;
  z-index: 1;

  height: 40px;
  padding: 8px 16px;
  background-color: var(--white);
  border-radius: 8px;
  border: 1px solid var(--gray);
  box-shadow: var(--boxShadow1);

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :active {
    background-color: var(--gray3);
  }
`;

const TabletStartCapturingButton = styled(Button)`
  && {
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, -50%);
    z-index: 998;
    border-radius: 12px;
    background-color: var(--purple7);

    :active {
      background-color: var(--purple9);
    }
  }
`;
