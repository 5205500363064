import React from "react";
import { ModalType, useModalStore } from "store/useModalStore";
import LoadingModal from "./LoadingModal";
import { LoadingModalProps } from "types/modal";
import GroupStudyChallengeModal from "./GroupStudyChallengeModal";

const ModalSwitcher = () => {
  const { modalState } = useModalStore();

  if (modalState.display) {
    switch (modalState.type) {
      case ModalType.LOADING:
        const loadingModalState = modalState as LoadingModalProps;
        return <LoadingModal text={loadingModalState.text} closable={false} target={loadingModalState.target} display={true} type={ModalType.LOADING} />;
      case ModalType.STUDY_CHALLENGE:
        return <GroupStudyChallengeModal closable={true} type="study_challenge" />;
    }

    if (modalState.type === ModalType.LOADING) {
      const loadingModalState = modalState as LoadingModalProps;
      return <LoadingModal text={loadingModalState.text} closable={false} target={loadingModalState.target} display={false} type={ModalType.LOADING} />;
    }
  }
  return <></>;
};

export default ModalSwitcher;
