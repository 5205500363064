import React, { useEffect, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import styled from "styled-components";
import { Typography16 } from "@slid/slid-ips";
import { v4 as uuidv4 } from "uuid";
import { setAutoNotesSTTDataResponseListener, setAutoNotesSTTErrorListener, setSTTSocketConnectionStatusListener } from "utils/extensionInterface/setListenerFromExtension";
import { sendRestartSTTRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { useAppDispatch, useAppSelector } from "hooks";
import store from "redux/store";
import * as Sentry from "@sentry/browser";
import { setEndAutoNotesSTT, setStartAutoNotes, setIsSocketConnected } from "redux/modules/autoNotesSlice";
import { AutoNotesSTTBlock, AutoNotesResultBlock, LocalSTTData } from "../types";
import { AutoNotesLoaderBlockData } from "components/editor/blocks/autoNotes/types";
import { getCurrentDocumentKey } from "utils/editor/util";
import { exportForAutoNotesLLMS } from "utils/exportNote";
import useEditorStore from "store/useEditorStore";
import { useTranslation } from "react-i18next";
import USER_PRIVILEGES, { SlidFeatures } from "utils/privilegeManager";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const COMPACT_FORCE_IS_FINAL_WORD_COUNT = 70;
const COMPACT_AUTO_NOTES_STT_BUFFER_WORD_COUNT = 60;

const FORCE_IS_FINAL_WORD_COUNT = 120;
const AUTO_NOTES_STT_BUFFER_WORD_COUNT = 100;

const COMPACT_LANGUAGES = ["한국어", "中文", "日本語"];

const AutoNotesMainUI = ({ width }: { width: number }) => {
  const [localSTTData, setLocalSTTData] = useState<LocalSTTData | null>(null);
  // TODO: migrate some of this state to redux so that it can be kept around when the main UI is un-mounted. (You want to keep the active block and the previous blocks around even if the main UI is un-mounted for use when it is mounted back.)
  const [activeAutoNotesSTTBlock, setActiveAutoNotesSTTBlock] = useState<AutoNotesSTTBlock>({
    finalizedText: "",
    wordCount: 0,
    blockId: uuidv4(),
  }); //TODO: rename to transcriptBuffer.
  const [previousAutoNoteSTTBlocks, setPreviousAutoNoteSTTBlocks] = useState<AutoNotesSTTBlock[]>([]); // these are STT data blocks. -> rename to previousTranscriptBufferBlocks.

  // this one is used to prevent insertion of the same transcript buffer block twice.
  const [insertedAutoNoteBlocks, setInsertedAutoNoteBlocks] = useState<AutoNotesResultBlock[]>([]); // these are the auto notes result blocks. -> rename to insertedTranscriptBufferBlocks.
  const dispatch = useAppDispatch();
  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo, currentDocument } = useAppSelector((state) => state.vdocs);
  const { isAutoNotesToggledOn, autoNotesVideoLang, autoNotesResultLang, isAutoNotesVisible, isAutoNotesActive, isSocketConnected } = useAppSelector((state) => state.autoNotes);
  const { updateNoteContent } = useEditorStore();
  const { t } = useTranslation("AutoNotes");
  const [forceRestartWordCount, setForceRestartWordCount] = useState(FORCE_IS_FINAL_WORD_COUNT);
  const [autoNotesSTTBufferWordCount, setAutoNotesSTTBufferWordCount] = useState(AUTO_NOTES_STT_BUFFER_WORD_COUNT);

  useEffect(() => {
    if (COMPACT_LANGUAGES.includes(autoNotesVideoLang)) {
      setForceRestartWordCount(COMPACT_FORCE_IS_FINAL_WORD_COUNT);
      setAutoNotesSTTBufferWordCount(COMPACT_AUTO_NOTES_STT_BUFFER_WORD_COUNT);
    } else {
      setForceRestartWordCount(FORCE_IS_FINAL_WORD_COUNT);
      setAutoNotesSTTBufferWordCount(AUTO_NOTES_STT_BUFFER_WORD_COUNT);
    }
  }, [autoNotesVideoLang]);

  const insertAutoNotesLoaderBlock = async (newblock: AutoNotesResultBlock, previousAutoNotesResultBlocks: AutoNotesResultBlock[]) => {
    //TODO: if you need to mark this block id as inserted in redux, you can do it here...
    updateNoteContent(true);
    const insertEditorBlock = async (editorMarkdownNotes: string) => {
      const editorInstance = store.getState().vdocs.editorInstance as EditorJS;
      if (!editorInstance) return;
      const { blocks } = editorInstance;
      const { insert: insertBlock } = blocks;
      const newTranscriptBlock = {
        finalizedText: newblock.finalizedText,
      };

      // NOTE: we either path these previous transcript blocks this way to show the LLM its previous work or we just save the editor contents and pass them for it as reference.

      // TODO: we might not have to do this once we have the script already added to the editor. OR we can save this data as state on the backend?? And have some kind of agent with state.
      const previousTranscriptBlocks = insertedAutoNoteBlocks //NOTE: this state object might become irrelavant as we put everything in the editor.
        .filter((block) => block.blockId !== newblock.blockId)
        .map((block) => {
          //TODO: we might be sending this data differently later.
          return {
            finalizedText: block.finalizedText,
            result: block.result,
          };
        });
      const blockData: AutoNotesLoaderBlockData = {
        newTranscriptBlock: newTranscriptBlock,
        previousTranscriptBlocks: previousTranscriptBlocks,
        language: autoNotesResultLang,
        previousNotes: { text: editorMarkdownNotes },
      };
      let insertIndex = editorInstance.blocks.getBlocksCount();
      if (insertIndex > 1) {
        const previousEditorBlock = editorInstance.blocks.getBlockByIndex(insertIndex - 1);
        if (previousEditorBlock && previousEditorBlock.isEmpty) {
          insertIndex--;
        }
      }
      insertBlock(
        "autoNotesLoader", // block type
        {
          ...blockData,
        }, // block tool data
        {}, // config - for when you want to pass some functions to the block.
        insertIndex, // insert index number
        false, // need to focus
        false, // replace?
        newblock.blockId // block id to insert block as.
      );
    };
    const currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return false;
    const markdownContent = await exportForAutoNotesLLMS();
    await insertEditorBlock(markdownContent);
    return true;
  };

  useEffect(() => {
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        dispatch(setIsSocketConnected(isSTTSocketConnected)); //socket must be connected for auto notes to work.
      },
    });
    setAutoNotesSTTDataResponseListener({
      responseHandler: (receivedData: any) => {
        setLocalSTTData(receivedData);
      },
    });

    setAutoNotesSTTErrorListener({
      responseHandler: (receivedData: any) => {
        console.log("AUTO NOTES STT ERROR - ", receivedData);
        // dispatch(setSTTError(receivedData.error));
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "STT Error during auto notes");
          Sentry.captureMessage(JSON.stringify(receivedData));
        });
      },
    });
  }, []);

  useEffect(() => {
    function forceSTTRestartIfBufferIsFull() {
      if (localSTTData && !localSTTData.isFinal) {
        if (localSTTData.text.split(" ").length > forceRestartWordCount) {
          sendRestartSTTRequestToParentWindow();
          // restarts slt to make it break at the desired word count
        }
      }
    }
    forceSTTRestartIfBufferIsFull();
  }, [localSTTData, forceRestartWordCount]);

  useEffect(() => {
    // console.log("localSTTData", localSTTData);
    function appendLocalSTTDataToActiveAutoNotesSTTBlockOnIsFinal() {
      if (localSTTData && localSTTData.isFinal) {
        //TODO: the active block will be built upto like 100 words...
        if (!localSTTData.text) return;
        setActiveAutoNotesSTTBlock((prev) => ({
          ...prev,
          finalizedText: `${prev.finalizedText} ${localSTTData.text}`,
          wordCount: prev.wordCount + localSTTData.text.split(" ").length,
        }));
      }
    }
    appendLocalSTTDataToActiveAutoNotesSTTBlockOnIsFinal();
  }, [localSTTData]);

  useEffect(() => {
    function flushSTTBufferIfFull() {
      if (activeAutoNotesSTTBlock.wordCount >= autoNotesSTTBufferWordCount) {
        setPreviousAutoNoteSTTBlocks((prev) => [...prev, { ...activeAutoNotesSTTBlock }]);
        setActiveAutoNotesSTTBlock({
          finalizedText: "",
          wordCount: 0,
          blockId: uuidv4(),
        });
        setLocalSTTData(null);
      }
    }
    flushSTTBufferIfFull();
  }, [activeAutoNotesSTTBlock, autoNotesSTTBufferWordCount]);

  useEffect(() => {
    function insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks() {
      const latestBlock = previousAutoNoteSTTBlocks[previousAutoNoteSTTBlocks.length - 1];
      if (!latestBlock) return;
      const isLatestBlockAlreadyInserted = insertedAutoNoteBlocks.some((block) => block.blockId === latestBlock.blockId);
      if (!isLatestBlockAlreadyInserted) {
        // generateAutoNotesResult({ finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: false });

        insertAutoNotesLoaderBlock({ finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: false }, [...insertedAutoNoteBlocks]);

        // NOTE: the latest block is now added to the auto notes result blocks marked as inserted.
        setInsertedAutoNoteBlocks((prev) => [...prev, { finalizedText: latestBlock.finalizedText, result: "", blockId: latestBlock.blockId, status: "default", isInserted: true }]); //TODO: move this to redux. It is for tracking which auto notes blocks have made it into the editor.
      }
    }
    insertAutoNotesLoaderBlockFromPreviousAutoNoteSTTBlocks();
  }, [previousAutoNoteSTTBlocks, insertedAutoNoteBlocks]);

  useEffect(() => {
    function savePendingSTTDataWhenVideoStops() {
      if (!isVideoPlaying && localSTTData) {
        dispatch(setEndAutoNotesSTT());
        setActiveAutoNotesSTTBlock((prev) => ({ ...prev, finalizedText: `${prev.finalizedText} ${localSTTData.text}`, wordCount: prev.wordCount + localSTTData?.text.split(" ").length }));
        setLocalSTTData(null);
      }
    }
    savePendingSTTDataWhenVideoStops();
  }, [isVideoPlaying, localSTTData, dispatch]);

  useEffect(() => {
    function startOrEndAutoNotes() {
      const shouldStartAutoNotesSTT = autoNotesVideoLang && autoNotesResultLang && isExtensionMediaPermitted && isAutoNotesToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);
      if (shouldStartAutoNotesSTT) {
        if (localSTTData?.text.length) {
          // NOTE: this covers the case when we are starting auto notes but find some localSTT data from the previous execution that was kept around.
          setActiveAutoNotesSTTBlock((prev) => ({ ...prev, finalizedText: `${prev.finalizedText} ${localSTTData.text}`, wordCount: prev.wordCount + localSTTData.text.split(" ").length }));
          setLocalSTTData(null);
        }
        dispatch(setStartAutoNotes());
        const isAutoNotesPermissionRequested = localStorage.getItem("isAutoNotesPermissionRequested");
        if (isAutoNotesPermissionRequested) {
          trackEvent({
            eventType: eventTypes.success.AUTO_NOTES_START,
          });
          localStorage.removeItem("isAutoNotesPermissionRequested");
        }
      } else {
        //end the auto notes stt as conditions are not met
        dispatch(setEndAutoNotesSTT());
      }
    }
    startOrEndAutoNotes();
    //don't include localSTTData.text in the dependency array.
  }, [autoNotesVideoLang, autoNotesResultLang, isVideoPlaying, isExtensionMediaPermitted, isAutoNotesToggledOn, currentVideo, dispatch]);

  return (
    <Background width={width} className="ce-block" isVisible={isAutoNotesVisible}>
      <AutoNoteContainer>
        <TitleWrapper>
          <Typography16 text={t("AutoNotes")} color="--gray17" weight={700} />
        </TitleWrapper>
        <MainContentWrapper>
          <AutoNotesTextContent>
            <ConfirmedSTTResult>{activeAutoNotesSTTBlock.finalizedText.trim() + " "}</ConfirmedSTTResult>
            {localSTTData?.text && <PendingSTTResult>{localSTTData.text.trim()}</PendingSTTResult>}
          </AutoNotesTextContent>
        </MainContentWrapper>
      </AutoNoteContainer>
    </Background>
  );
};

export default AutoNotesMainUI;

const Background = styled.div<{ width: number; isVisible: boolean }>`
  position: absolute;
  bottom: 96px;
  //   max-width: 470px;
  width: 80%;
  max-width: 696px;
  left: 50%;
  transform: translateX(-48%);

  border: 1px solid var(--blue7);
  border-radius: 12px;
  z-index: 990;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  &:hover {
    opacity: 1;
    display: block;
  }
  background-color: white;
`;

const AutoNoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px;
  gap: 8px;
`;

const ConfirmedSTTResult = styled.span`
  color: var(--gray17);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  display: inline;
`;

const PendingSTTResult = styled.span`
  color: var(--gray11);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  display: inline;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 24rem;
`;

const AutoNotesTextContent = styled.div`
  direction: rtl;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
